import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 4 NVQ Diploma in Construction Site Supervision (Construction)
        - Retrofit is tailored for individuals working within Construction Site
        Supervision (Construction) - Retrofit. This qualification demonstrates
        your ability to apply your skills in Construction Site Supervision
        (Construction) - Retrofit, ensuring compliance with health and safety
        standards and legal requirements.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Developing and maintaining good occupational working
                  relationships in the workplace
                </li>
                <li>
                  Implementing, maintaining and reviewing systems for health,
                  safety, welfare, wellbeing and environmental protection in the
                  workplace
                </li>
                <li>Assessing and agreeing work methods in the workplace</li>
                <li>
                  Planning work activities and resources to meet project
                  requirements in the workplace
                </li>
                <li>Coordinating work control in the workplace</li>
                <li>
                  Controlling work progress against agreed programmes in the
                  workplace
                </li>
                <li>
                  Allocating and monitoring the use of plant, equipment or
                  machinery in the workplace
                </li>
                <li>
                  Implementing communication systems for the project in the
                  workplace
                </li>
                <li>
                  Controlling work against agreed quality standards in the
                  workplace
                </li>
                <li>
                  Contributing to the control of work quantities and costs in
                  the workplace
                </li>
                <li>
                  Supervising the pre-installation planning for retrofit works
                  in the workplace
                </li>
                <li>
                  Supervising the installation, commissioning and handover of
                  retrofit works in the workplace
                </li>
                <li>
                  Maintaining the dimensional accuracy of work in the workplace
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units: two optional units are required
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Maintaining supplies of materials to meet project requirements
                  in the workplace
                </li>
                <li>
                  Coordinating preparation for site operations in the workplace
                </li>
                <li>
                  Allocating work and monitoring people's performance in the
                  workplace
                </li>
                <li>Enabling learning opportunities in the workplace</li>
                <li>
                  Contributing to the identification of a work team in the
                  workplace
                </li>
                <li>Handing over property in the workplace</li>
                <li>
                  Providing customer services in the construction workplace
                </li>
                <li>
                  Supervising activities to traditional and heritage buildings
                  and structures in the workplace
                </li>
                <li>Managing your personal development in the workplace</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
