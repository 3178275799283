import React from "react";

const EntryRequirement = () => {
  const requirements = [
    {
      number: 1,
      text: "Professionals actively working in suspended ceiling installation.  ",
    },
    {
      number: 2,
      text: "Individuals familiar with health, safety, and productive work practices in construction.  ",
    },
    {
      number: 3,
      text: "Those able to provide workplace evidence demonstrating their ceiling fixing skills.    ",
    },
  ];

  return (
    <div className="bg-gray-100 border border-gray-200 p-6 md:p-10 max-w-7xl mx-auto rounded-2xl shadow-xl">
      {/* Heading */}
      <h2 className="text-xl md:text-2xl font-semibold text-center mb-4">
        Entry Requirement
      </h2>

      {/* Subheading */}
      <p className="text-center text-gray-600 mb-8">
        No formal entry requirements exist, but this qualification is ideal for
        individuals with experience in ceiling fixing or interior systems.{" "}
        <br />
        This qualification is suited for individuals already working in the
        construction industry with experience in ceiling fixing. Ideal
        candidates include:
      </p>

      {/* Circles Section */}
      <div className="flex flex-col md:flex-row justify-evenly items-center gap-6">
        {requirements.map((item) => (
          <div
            key={item.number}
            className="w-64 h-64 flex flex-col items-center justify-center rounded-full bg-[#0C1524] text-white text-center p-4">
            {/* Number */}
            {/* <span className="w-12 h-12 flex items-center justify-center rounded-full bg-white text-black font-semibold text-lg mb-2">
              {item.number}
            </span> */}
            {/* Text */}
            <p className="text-sm md:text-base leading-relaxed font-thin px-4">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EntryRequirement;
