import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 2 Certificate in Construction Plant Operations is tailored for
        individuals working within construction plant operations. This
        qualification demonstrates your ability to work within construction
        plant operations, ensuring compliance with health and safety standards
        and legal requirements.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Site safety and roles and responsibilities for plant operators{" "}
                </li>
                <li>Sustainability in the Construction Industry </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units: a minimum of 15 credits from Optional Units must be
            achieved
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>Operating a Forward Tipping Dumper: 5 credits</li>
                <li>
                  Operating an excavator 180 degree – below 5 tonnes: 5 credits
                </li>
                <li>
                  Operating an excavator 180 degree – above 5 tonnes: 5 credits
                </li>
                <li>Operating a ride-on roller: 5 credits</li>
                <li>
                  Operating an excavator 360 degree – below 10 tonnes: 5 credits
                </li>
                <li>
                  Operating an excavator 360 degree – above 10 tonnes: 5 credits
                </li>
                <li>Operating a skid steer loader: 5 credits</li>
                <li>Operating a skip handler: 5 credits</li>
                <li>Operating a dump truck – rigid chassis: 5 credits</li>
                <li>Operating a dump truck – articulated chassis: 5 credits</li>
                <li>Operating a rough terrain forklift: 5 credits</li>
                <li>Operating a telescopic handler: 5 credits</li>
                <li>Operating a hoist: 5 credits</li>
                <li>
                  Operating a Mobile Elevating Work Platform – scissor: 5
                  credits
                </li>
                <li>
                  Operating a Mobile Elevating Work Platform – boom: 5 credits
                </li>
                <li>Operating a concrete pump – trailer mounted: 5 credits</li>
                <li>Operating a grader: 5 credits</li>
                <li>Operating a wheeled loading shovel: 5 credits</li>
                <li>Operating a crawler-tractor/dozer: 5 credits</li>
                <li>
                  Operating a concrete pump – truck mounted boom: 5 credits
                </li>
                <li>Operating a tracked loading shovel: 5 credits</li>
                <li>Operating a motorised scraper: 5 credits</li>
                <li>Operating a crawler-tractor – side boom: 5 credits</li>
                <li>Operating a trencher: 5 credits</li>
                <li>Operating a crawler crane: 5 credits</li>
                <li>Operating a tower crane: 10 credits</li>
                <li>Operating a lorry loader: 5 credits</li>
                <li>Performing slinger/signaller duties: 5 credits</li>
                <li>Operating a mobile crane: 5 credits</li>
                <li>Operating a pedestrian operated tower crane: 5 credits</li>
                <li>Operating a compact crane: 5 credits</li>
                <li>Operating a crusher: 5 credits</li>
                <li>Operating a screener: 5 credits</li>
                <li>Operating a dragline: 5 credits</li>
                <li>Operating a piling rig – tripod: 5 credits</li>
                <li>
                  Operating a piling rig – driven below 15 tonnes: 5 credits
                </li>
                <li>
                  Operating a piling rig – driven above 15 tonnes: 5 credits
                </li>
                <li>
                  Operating a piling rig – bored below 15 tonnes: 5 credits
                </li>
                <li>
                  Operating a piling rig – bored above 15 tonnes: 5 credits
                </li>
                <li>Operating an agricultural tractor: 5 credits</li>
                <li>Operating an industrial forklift truck: 5 credits</li>
                <li>Operating a reach truck: 5 credits</li>
                <li>Operating a transporter loader/securer STGO: 5 credits</li>
                <li>
                  Operating a transporter loader/securer non-STGO: 5 credits
                </li>
                <li>Operating a loader compressor: 5 credits</li>
                <li>Operating a soil/landfill compactor: 5 credits</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
