import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The NVQ Level 2 Certificate in Interior Systems (Construction) - Cavity
        Barrier Installation is tailored for professionals responsible for the
        installation of cavity barrier systems. It validates your skills and
        competency in this essential construction practice, ensuring compliance
        with safety and building standards.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Conforming to General Health, Safety, and Welfare in the
                  Workplace
                </li>
                <li>
                  Conforming to Productive Working Practices in the Workplace{" "}
                </li>
                <li>
                  Moving, Handling, and Storing Resources in the Workplace{" "}
                </li>
                <li>
                  Preparing and installing protection barriers to floors and
                  ceilings in the workplace
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units:
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>NA </li>
              </ul>
            </div>
          )}
        </div>
        Note: The specialized units focus on the correct and efficient
        installation of cavity barrier systems to ensure fire safety and
        compliance with building regulations.
      </div>
    </div>
  );
};

export default AssessmentBox;
