import React from "react";
import WhyChooseUs from "../../components/WhyChooseM2hse/WhyM2hse";
import backgroundImage from "../../assets/Courses/218dc37ca844a835bb8ab8f4e3b625bd.png";
const HealthSafetyConsultancy = () => {
  return (
    <div className="bg-gray-50 font-poppins mt-24 md:mt-28">
      {/* Banner Section */}
      <div
        className="relative bg-cover bg-center h-[350px] flex flex-col items-center justify-center px-4"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}>
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-90"></div>

        {/* Heading */}
        <h1 className="text-white relative mt-6 text-3xl md:text-4xl lg:text-5xl font-bold text-center">
          Health & Safety Consultancy for Construction
        </h1>

        {/* TrustBox Widget */}
        <div
          className="trustpilot-widget my-4 relative z-10"
          data-locale="en-GB"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="65e09121938229973209b5ac"
          data-style-height="120px"
          data-style-width="100%"
          data-theme="dark">
          <a
            href="https://uk.trustpilot.com/review/m2hse.co.uk"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white">
            Trustpilot
          </a>
        </div>
      </div>

      {/* Introduction Section */}
      <section className="text-center py-10 md:py-14 px-6 bg-white">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800">
          Tailored Health & Safety Solutions
        </h2>
        <p className="mt-4 text-base md:text-lg text-gray-600 max-w-4xl mx-auto">
          We simplify health and safety compliance for construction companies in
          the UK. Our services ensure your workplace stays safe, legal, and
          productive.
        </p>
      </section>

      {/* Feature Cards */}
      <section className="py-12 md:py-16 px-6 bg-gray-100">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 max-w-6xl mx-auto">
          {/* Card 1 */}
          <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6">
            <h3 className="text-xl md:text-2xl font-bold text-[#FF3131]">
              Unlimited Expert Support
            </h3>
            <p className="mt-4 text-gray-600 text-sm md:text-base">
              Access unlimited telephone and email support to resolve your
              health and safety concerns efficiently.
            </p>
          </div>

          {/* Card 2 */}
          <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6">
            <h3 className="text-xl md:text-2xl font-bold text-[#FF3131]">
              Incident Support
            </h3>
            <p className="mt-4 text-gray-600 text-sm md:text-base">
              We provide guidance and support to effectively manage workplace
              incidents and maintain safety compliance.
            </p>
          </div>

          {/* Card 3 */}
          <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6">
            <h3 className="text-xl md:text-2xl font-bold text-[#FF3131]">
              Risk Assessments
            </h3>
            <p className="mt-4 text-gray-600 text-sm md:text-base">
              Get expert help to develop and implement comprehensive risk
              assessments tailored to your worksite.
            </p>
          </div>
        </div>
      </section>

      {/* Service Highlights */}
      <section className="py-12 md:py-16 px-6 bg-white">
        <div className="max-w-6xl mx-auto text-center space-y-8 md:space-y-12">
          <div className="space-y-4">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800">
              Our Key Services
            </h2>
            <p className="text-base md:text-lg text-gray-600">
              We offer a range of services to meet your health and safety needs.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
            {/* Highlight 1 */}
            <div className="p-6 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 transition duration-300">
              <h4 className="text-lg md:text-xl font-semibold text-[#FF3131]">
                Toolbox Talks
              </h4>
              <p className="mt-3 text-gray-700 text-sm md:text-base">
                Interactive sessions to keep your workforce updated and safety
                focused.
              </p>
            </div>

            {/* Highlight 2 */}
            <div className="p-6 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 transition duration-300">
              <h4 className="text-lg md:text-xl font-semibold text-[#FF3131]">
                SSIP Accreditation
              </h4>
              <p className="mt-3 text-gray-700 text-sm md:text-base">
                Simplify SSIP accreditation with expert guidance and support.
              </p>
            </div>

            {/* Highlight 3 */}
            <div className="p-6 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 transition duration-300">
              <h4 className="text-lg md:text-xl font-semibold text-[#FF3131]">
                Staff Training Assessment
              </h4>
              <p className="mt-3 text-gray-700 text-sm md:text-base">
                Identify training needs to improve staff skills and compliance.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="bg-gray-50 py-12 md:py-16 px-6">
        <WhyChooseUs />
      </section>

      {/* Contact Section */}
      <section className="py-12 md:py-16 text-center">
        <h2 className="text-2xl md:text-3xl lg:text-4xl text-[#0C1524] font-bold">
          Ready to Improve Your Workplace Safety?
        </h2>
        <p className="mt-4 text-base md:text-lg text-[#0C1524] max-w-3xl mx-auto">
          Contact us today for a free, no-obligation quote. Let us help you
          create a safer and compliant work environment.
        </p>
        <a href="/contact-us">
          <button className="mt-8 bg-[#FF3131] text-white font-bold py-3 px-8 rounded-lg shadow-md hover:bg-gray-200 transition-all duration-300">
            Get a Free Quote
          </button>
        </a>
      </section>
    </div>
  );
};

export default HealthSafetyConsultancy;
