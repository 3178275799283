import { useNavigate } from "react-router-dom";

export default function NotFound({ entity = "/" }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center p-8 bg-white rounded-lg shadow-md">
        <h1 className="text-4xl font-bold text-red-500 mb-4">404 Not Found</h1>
        <p className="text-lg text-gray-500 mb-6">
          Sorry, the page you are looking for does not exist.
        </p>
        <button
          onClick={() => navigate(entity)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Go Home
        </button>
      </div>
    </div>
  );
}
