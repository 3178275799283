import React, { useState } from "react";
import backgroundImage from "../../assets/Courses/218dc37ca844a835bb8ab8f4e3b625bd.png";

const PrivacyPolicy = () => {
  const [consent, setConsent] = useState(null);
  const handleAccept = () => {
    setConsent(true);
    alert("You have accepted the Privacy Policy.");
  };

  const handleDecline = () => {
    setConsent(false);
    alert("You have declined the Privacy Policy.");
  };

  if (consent === true) {
    return (
      <div className="mt-40 italic text-[#FF3131] font-bold text-center text-4xl">
        Thank you for accepting the Privacy Policy.
      </div>
    );
  }

  if (consent === false) {
    return (
      <div className="mt-40 italic text-[#FF3131] font-bold text-center text-4xl">
        You have declined the Privacy Policy.
      </div>
    );
  }
  return (
    <div
      className="relative min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Semi-transparent overlay */}
      {/* <div className="absolute inset-0 bg-white-100 opacity-90"></div> */}
      <div className="absolute inset-0 bg-white/95"></div>

      {/* Content */}
      <div className="relative z-10 mt-[80px] flex flex-col items-center justify-center min-h-screen px-6 text-center md:px-12">
        <div className="bg-[#0C1524]/80 mt-[80px] rounded-lg p-6 md:p-12 shadow-lg max-w-4xl">
          {/* Heading */}
          <h1 className="text-2xl md:text-4xl text-left font-bold text-[#FF3131] mb-4">
            Privacy Policy
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            We respect your privacy and are committed to protecting it through
            our compliance with this privacy policy (“Policy”). This Policy
            describes the types of information we may collect from you or that
            you may provide (“Personal Information”) on the M2HSE.co.uk website
            (“Website” or “Service”) and any of its related products and
            services (collectively, “Services”), and our practices for
            collecting, using, maintaining, protecting, and disclosing that
            Personal Information. It also describes the choices available to you
            regarding our use of your Personal Information and how you can
            access and update it.
          </p>
          {/* Horizontal rule */}
          <hr className="border-gray-300 my-4" />
          {/* Placeholder for more content */}
          <p className="text-white text-left leading-relaxed mb-6">
            This Policy is a legally binding agreement between you (“User”,
            “you” or “your”) and M2HSE Consulting & Recruitment LTD (“M2HSE
            Training LTD”, “we”, “us” or “our”). If you are entering into this
            agreement on behalf of a business or other legal entity, you
            represent that you have the authority to bind such entity to this
            agreement, in which case the terms “User”, “you” or “your” shall
            refer to such entity. If you do not have such authority, or if you
            do not agree with the terms of this agreement, you must not accept
            this agreement and may not access and use the Website and Services.
            By accessing and using the Website and Services, you acknowledge
            that you have read, understood, and agree to be bound by the terms
            of this Policy. This Policy does not apply to the practices of
            companies that we do not own or control, or to individuals that we
            do not employ or manage.
          </p>{" "}
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Collection of Personal Information
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            You can access and use the Website and Services without telling us
            who you are or revealing any information by which someone could
            identify you as a specific, identifiable individual. If, however,
            you wish to use some of the features offered on the Website, you may
            be asked to provide certain Personal Information (for example, your
            name and e-mail address).
          </p>
          <ul className=" list-disc  text-left text-white mb-6">
            We receive and store any information you knowingly provide to us
            when you make a purchase, or fill any forms on the Website. When
            required, this information may include the following:
            <li className="ml-8">
              Account details (such as user name, unique user ID, password, etc)
            </li>
            <li className="ml-8">
              Contact information (such as email address, phone number, etc)
            </li>
            <li className="ml-8">
              Basic personal information (such as name, country of residence,
              etc)
            </li>
          </ul>
          <p className="text-white text-left leading-relaxed">
            You can choose not to provide us with your Personal Information, but
            then you may not be able to take advantage of some of the features
            on the Website. Users who are uncertain about what information is
            mandatory are welcome to contact us.
          </p>
          {/* Horizontal rule */}
          <hr className="border-gray-300 my-4" />
          <p className="text-white text-left italic leading-relaxed">
            This document was last updated on October 19, 2023
          </p>
          {/* Horizontal rule */}
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Privacy of Children
          </h1>
          <p className="text-white text-left leading-relaxed mb-6">
            We do not knowingly collect any Personal Information from children
            under the age of 18. If you are under the age of 18, please do not
            submit any Personal Information through the Website and Services. If
            you have reason to believe that a child under the age of 18 has
            provided Personal Information to us through the Website and
            Services, please contact us to request that we delete that child’s
            Personal Information from our Services.
          </p>{" "}
          <p className="text-white text-left leading-relaxed mb-6">
            We encourage parents and legal guardians to monitor their children’s
            Internet usage and to help enforce this Policy by instructing their
            children never to provide Personal Information through the Website
            and Services without their permission. We also ask that all parents
            and legal guardians overseeing the care of children take the
            necessary precautions to ensure that their children are instructed
            to never give out Personal Information when online without their
            permission.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Use and Processing of Collected Information
          </h1>
          <p className="text-white text-left leading-relaxed mb-6">
            We act as a data controller and a data processor in terms of the
            GDPR when handling Personal Information, unless we have entered into
            a data processing agreement with you in which case you would be the
            data controller and we would be the data processor.
          </p>{" "}
          <p className="text-white text-left leading-relaxed mb-6">
            Our role may also differ depending on the specific situation
            involving Personal Information. We act in the capacity of a data
            controller when we ask you to submit your Personal Information that
            is necessary to ensure your access and use of the Website and
            Services. In such instances, we are a data controller because we
            determine the purposes and means of the processing of Personal
            Information and we comply with data controllers’ obligations set
            forth in the GDPR.
          </p>
          <hr className="border-gray-300 my-4" />
          <p className="text-white text-left leading-relaxed mb-6">
            We act in the capacity of a data processor in situations when you
            submit Personal Information through the Website and Services. We do
            not own, control, or make decisions about the submitted Personal
            Information, and such Personal Information is processed only in
            accordance with your instructions. In such instances, the User
            providing Personal Information acts as a data controller in terms of
            the GDPR.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            In order to make the Website and Services available to you, or to
            meet a legal obligation, we may need to collect and use certain
            Personal Information. If you do not provide the information that we
            request, we may not be able to provide you with the requested
            products or services. Any of the information we collect from you may
            be used for the following purposes:
          </p>
          <ul className=" list-disc  text-left text-white mb-6">
            <li className="ml-8">Create and manage user accounts</li>
            <li className="ml-8">Fulfill and manage orders</li>
            <li className="ml-8">Deliver products or services</li>
            <li className="ml-8">Improve products and services</li>
            <li className="ml-8">Send administrative information</li>
            <li className="ml-8">
              Send marketing and promotional communications
            </li>
            <li className="ml-8">Respond to inquiries and offer support</li>
            <li className="ml-8">Request user feedback</li>
            <li className="ml-8">Improve user experience</li>
            <li className="ml-8">Post customer testimonials</li>
            <li className="ml-8">Deliver targeted advertising</li>
            <li className="ml-8">Administer prize draws and competitions</li>
            <li className="ml-8">Enforce terms and conditions and policies</li>
            <li className="ml-8">Protect from abuse and malicious users</li>
            <li className="ml-8">Respond to legal requests and prevent harm</li>
            <li className="ml-8">Run and operate the Website and Services</li>
          </ul>
          <ul className=" list-disc  text-left text-white mb-6">
            Processing your Personal Information depends on how you interact
            with the Website and Services, where you are located in the world
            and if one of the following applies:
            <li className="ml-8">
              you have given your consent for one or more specific purposes;
              this, however, does not apply, whenever the processing of Personal
              Information is subject to European data protection law;
            </li>
            <li className="ml-8">
              provision of information is necessary for the performance of an
              agreement with you and/or for any pre-contractual obligations
              thereof;
            </li>
            <li className="ml-8">
              processing is necessary for compliance with a legal obligation to
              which you are subject;
            </li>
            <li className="ml-8">
              processing is related to a task that is carried out in the public
              interest or in the exercise of official authority vested in us;
            </li>
            <li className="ml-8">
              processing is necessary for the purposes of the legitimate
              interests pursued by us or by a third party.
            </li>
          </ul>
          <p className="text-white text-left leading-relaxed mb-6">
            Note that under some legislations we may be allowed to process
            information until you object to such processing by opting out,
            without having to rely on consent or any other of the legal bases.
            In any case, we will be happy to clarify the specific legal basis
            that applies to the processing, and in particular, whether the
            provision of Personal Information is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Payment Processing
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            In case of Services requiring payment, you may need to provide your
            credit card details or other payment account information, which will
            be used solely for processing payments. We use third-party payment
            processors (“Payment Processors”) to assist us in processing your
            payment information securely.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            Payment Processors adhere to the latest security standards as
            managed by the PCI Security Standards Council, which is a joint
            effort of brands like Visa, MasterCard, American Express, and
            Discover. Sensitive and private data exchange happens over an SSL
            secured communication channel and is encrypted and protected with
            digital signatures, and the Website and Services are also in
            compliance with strict vulnerability standards in order to create as
            secure of an environment as possible for Users. We will share
            payment data with the Payment Processors only to the extent
            necessary for the purposes of processing your payments, refunding
            such payments, and dealing with complaints and queries related to
            such payments and refunds.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            Please note that the Payment Processors may collect some Personal
            Information from you, which allows them to process your payments
            (e.g., your email address, address, credit card details, and bank
            account number) and handle all the steps in the payment process
            through their systems, including data collection and data
            processing. The Payment Processors’ use of your Personal Information
            is governed by their respective privacy policies which may or may
            not contain privacy protections as protective as this Policy. We
            suggest that you review their respective privacy policies.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Disclosure of Information
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            Depending on the requested Services or as necessary to complete any
            transaction or provide any Service you have requested, we may share
            your information with our trusted subsidiaries and joint venture
            partners, affiliates, contracted companies, and service providers
            (collectively, “Service Providers”) we rely upon to assist in the
            operation of the Website and Services available to you and whose
            privacy policies are consistent with ours or who agree to abide by
            our policies with respect to Personal Information. We will not share
            any personally identifiable information with third parties and will
            not share any information with unaffiliated third parties.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            Service Providers are not authorized to use or disclose your
            information except as necessary to perform services on our behalf or
            comply with legal requirements. Service Providers are given the
            information they need only in order to perform their designated
            functions, and we do not authorize them to use or disclose any of
            the provided information for their own marketing or other purposes.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            We may also disclose any Personal Information we collect, use or
            receive if required or permitted by law, such as to comply with a
            subpoena or similar legal process, and when we believe in good faith
            that disclosure is necessary to protect our rights, protect your
            safety or the safety of others, investigate fraud, or respond to a
            government request.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Retention of Information
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            We will retain and use your Personal Information for the period
            necessary to enforce our agreements, resolve disputes, and unless a
            longer retention period is required or permitted by law up to a
            maximum of 60 months.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            We may use any aggregated data derived from or incorporating your
            Personal Information after you update or delete it, but not in a
            manner that would identify you personally. Once the retention period
            expires, Personal Information shall be deleted. Therefore, the right
            to access, the right to erasure, the right to rectification, and the
            right to data portability cannot be enforced after the expiration of
            the retention period.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Transfer of Information
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            Depending on your location, data transfers may involve transferring
            and storing your information in a country other than your own.
            However, this will not include countries outside the European Union
            and European Economic Area. If any such transfer takes place, you
            can find out more by checking the relevant sections of this Policy
            or inquire with us using the information provided in the contact
            section.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Data Protection Rights under the GDPR
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            If you are a resident of the European Economic Area (“EEA”), you
            have certain data protection rights and we aim to take reasonable
            steps to allow you to correct, amend, delete, or limit the use of
            your Personal Information. If you wish to be informed what Personal
            Information we hold about you and if you want it to be removed from
            our systems, please contact us. In certain circumstances, you have
            the following data protection rights:
          </p>
          <ul className=" list-disc  text-left text-white mb-6">
            <li className="ml-8">
              You have the right to withdraw consent where you have previously
              given your consent to the processing of your Personal Information.
              Withdrawal will not affect the lawfulness of processing before the
              withdrawal.
            </li>
            <li className="ml-8">
              You have the right to learn if your Personal Information is being
              processed by us, obtain disclosure regarding certain aspects of
              the processing, and obtain a copy of your Personal Information
              undergoing processing.
            </li>
            <li className="ml-8">
              You have the right to verify the accuracy of your information and
              ask for it to be updated or corrected. You also have the right to
              request us to complete the Personal Information you believe is
              incomplete.
            </li>
            <li className="ml-8">
              You have the right to object to the processing of your information
              if the processing is carried out on a legal basis other than
              consent. Where Personal Information is processed for the public
              interest, in the exercise of an official authority vested in us,
              or for the purposes of the legitimate interests pursued by us, you
              may object to such processing by providing a ground related to
              your particular situation to justify the objection. You must know
              that, however, should your Personal Information be processed for
              direct marketing purposes, you can object to that processing at
              any time without providing any justification. To learn whether we
              are processing Personal Information for direct marketing purposes,
              you may refer to the relevant sections of this Policy.
            </li>
            <li className="ml-8">
              You have the right, under certain circumstances, to restrict the
              processing of your Personal Information. These circumstances
              include: the accuracy of your Personal Information is contested by
              you and we must verify its accuracy; the processing is unlawful,
              but you oppose the erasure of your Personal Information and
              request the restriction of its use instead; we no longer need your
              Personal Information for the purposes of processing, but you
              require it to establish, exercise or defend your legal claims; you
              have objected to processing pending the verification of whether
              our legitimate grounds override your legitimate grounds. Where
              processing has been restricted, such Personal Information will be
              marked accordingly and, with the exception of storage, will be
              processed only with your consent or for the establishment, to
              exercise or defence of legal claims, for the protection of the
              rights of another natural, or legal person or for reasons of
              important public interest.
            </li>
            <li className="ml-8">
              You have the right, under certain circumstances, to obtain the
              erasure of your Personal Information from us. These circumstances
              include: the Personal Information is no longer necessary in
              relation to the purposes for which it was collected or otherwise
              processed; you withdraw consent to consent-based processing; you
              object to the processing under certain rules of applicable data
              protection law; the processing is for direct marketing purposes;
              and the personal data have been unlawfully processed. However,
              there are exclusions of the right to erasure such as where
              processing is necessary: for exercising the right of freedom of
              expression and information; for compliance with a legal
              obligation; or for the establishment, to exercise or defence of
              legal claims.
            </li>
            <li className="ml-8">
              You have the right to receive your Personal Information that you
              have provided to us in a structured, commonly used, and
              machine-readable format and, if technically feasible, to have it
              transmitted to another controller without any hindrance from us,
              provided that such transmission does not adversely affect the
              rights and freedoms of others
            </li>
            <li className="ml-8">
              You have the right to complain to a data protection authority
              about our collection and use of your Personal Information. If you
              are not satisfied with the outcome of your complaint directly with
              us, you have the right to lodge a complaint with your local data
              protection authority. For more information, please contact your
              local data protection authority in the EEA. This provision is
              applicable provided that your Personal Information is processed by
              automated means and that the processing is based on your consent,
              on a contract which you are part of, or on pre-contractual
              obligations thereof.
            </li>
          </ul>
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            How to Exercise Your Rights
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            Any requests to exercise your rights can be directed to us through
            the contact details provided in this document. Please note that we
            may ask you to verify your identity before responding to such
            requests. Your request must provide sufficient information that
            allows us to verify that you are the person you are claiming to be
            or that you are the authorized representative of such person. If we
            receive your request from an authorized representative, we may
            request evidence that you have provided such an authorized
            representative with power of attorney or that the authorized
            representative otherwise has valid written authority to submit
            requests on your behalf.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            You must include sufficient details to allow us to properly
            understand the request and respond to it. We cannot respond to your
            request or provide you with Personal Information unless we first
            verify your identity or authority to make such a request and confirm
            that the Personal Information relates to you.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Do Not Track Signals{" "}
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            Some browsers incorporate a Do Not Track feature that signals to
            websites you visit that you do not want to have your online activity
            tracked. Tracking is not the same as using or collecting information
            in connection with a website. For these purposes, tracking refers to
            collecting personally identifiable information from consumers who
            use or visit a website or online service as they move across
            different websites over time. The Website and Services do not track
            its visitors over time and across third-party websites. However,
            some third-party websites may keep track of your browsing activities
            when they serve you content, which enables them to tailor what they
            present to you.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Advertisements
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            We may permit certain third-party companies to help us tailor
            advertising that we think may be of interest to Users and to collect
            and use other data about User activities on the Website. These
            companies may deliver ads that might place cookies and otherwise
            track User behaviour.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Google Ads
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            We are using Google as part of our marketing strategies, and cookies
            might be used for personalization of ads and future service
            provider. For more details on how Google will use data collected,
            please access their Privacy and Terms on the following link: Google
            Privacy and Terms
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Social Media Features
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            Our Website and Services may include social media features, such as
            the Facebook and Twitter buttons, Share This buttons, etc
            (collectively, “Social Media Features”). These Social Media Features
            may collect your IP address, what page you are visiting on our
            Website and Services, and may set a cookie to enable Social Media
            Features to function properly. Social Media Features are hosted
            either by their respective providers or directly on our Website and
            Services. Your interactions with these Social Media Features are
            governed by the privacy policy of their respective providers.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Affiliate Links
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            We may engage in affiliate marketing and have affiliate links
            present on the Website and Services for the purpose of being able to
            offer you related or additional products and services. If you click
            on an affiliate link, a cookie will be placed on your browser to
            track any sales for purposes of commissions.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Links to Other Resources
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            The Website and Services contain links to other resources that are
            not owned or controlled by us. Please be aware that we are not
            responsible for the privacy practices of such other resources or
            third parties. We encourage you to be aware when you leave the
            Website and Services and to read the privacy statements of each and
            every resource that may collect Personal Information.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Information Security
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            We secure information you provide on computer servers in a
            controlled, secure environment, protected from unauthorized access,
            use, or disclosure. We maintain reasonable administrative,
            technical, and physical safeguards in an effort to protect against
            unauthorized access, use, modification, and disclosure of Personal
            Information in our control and custody. However, no data
            transmission over the Internet or wireless network can be
            guaranteed.
          </p>
          <ul className=" list-disc  text-left text-white mb-6">
            Therefore, while we strive to protect your Personal Information, you
            acknowledge that:
            <li className="ml-8">
              there are security and privacy limitations of the Internet which
              are beyond our control;
            </li>
            <li className="ml-8">
              the security, integrity, and privacy of any and all information
              and data exchanged between you and the Website and Services cannot
              be guaranteed;
            </li>
            <li className="ml-8">
              any such information and data may be viewed or tampered with in
              transit by a third party, despite best efforts.
            </li>
          </ul>
          <p className="text-white text-left leading-relaxed mb-6">
            As the security of Personal Information depends in part on the
            security of the device you use to communicate with us and the
            security you use to protect your credentials, please take
            appropriate measures to protect this information.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Data Breach
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            In the event we become aware that the security of the Website and
            Services has been compromised or Users’ Personal Information has
            been disclosed to unrelated third parties as a result of external
            activity, including, but not limited to, security attacks or fraud,
            we reserve the right to take reasonably appropriate measures,
            including, but not limited to, investigation and reporting, as well
            as notification to and cooperation with law enforcement authorities.
            In the event of a data breach, we will make reasonable efforts to
            notify affected individuals if we believe that there is a reasonable
            risk of harm to the User as a result of the breach or if notice is
            otherwise required by law. When we do, we will post a notice on the
            Website.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Changes and Amendments{" "}
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            We reserve the right to modify this Policy or its terms related to
            the Website and Services at any time at our discretion. When we do,
            we will revise the updated date at the bottom of this page. We may
            also provide notice to you in other ways at our discretion, such as
            through the contact information you have provided.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            An updated version of this Policy will be effective immediately upon
            the posting of the revised Policy unless otherwise specified. Your
            continued use of the Website and Services after the effective date
            of the revised Policy (or such other act specified at that time)
            will constitute your consent to those changes. However, we will not,
            without your consent, use your Personal Information in a manner
            materially different than what was stated at the time your Personal
            Information was collected.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Acceptance of this policy
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            You acknowledge that you have read this Policy and agree to all its
            terms and conditions. By accessing and using the Website and
            Services and submitting your information you agree to be bound by
            this Policy. If you do not agree to abide by the terms of this
            Policy, you are not authorized to access or use the Website and
            Services.
          </p>
          <hr className="border-gray-300 my-4" />
          <h1 className="text-xl md:text-2xl text-left font-bold text-[#FF3131] mb-4">
            Contact Us
          </h1>
          {/* Text */}
          <p className="text-white text-left leading-relaxed mb-6">
            If you have any questions, concerns, or complaints regarding this
            Policy, the information we hold about you, or if you wish to
            exercise your rights, we encourage you to contact us using the
            details below:
          </p>
          <div className="flex flex-col mb-6 md:flex-row justify-left items-center gap-4 p-6 ">
            {/* Email Button */}
            <button className="px-6 py-3 bg-gray-200 text-black text-sm md:text-base font-medium border border-red-500 rounded shadow-md hover:bg-gray-300 transition">
              Email: sales@m2hse.co.uk
            </button>
            {/* Telephone Button */}
            <button className="px-6 py-3 bg-gray-200 text-black text-sm md:text-base font-medium border border-red-500 rounded shadow-md hover:bg-gray-300 transition">
              Telephone: 07488 891088
            </button>
          </div>
          <p className="text-white text-left leading-relaxed mb-6">
            We will attempt to resolve complaints and disputes and make every
            reasonable effort to honor your wish to exercise your rights as
            quickly as possible and in any event, within the timescales provided
            by applicable data protection laws.
          </p>
          <p className="text-white text-left leading-relaxed mb-6">
            This document was last updated on October 19, 2023
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={handleAccept}
              className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition">
              Accept
            </button>
            <button
              onClick={handleDecline}
              className="px-6 py-3 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition">
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
