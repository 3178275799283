import React, { useEffect, useState } from "react";
import { useCart } from "../../CartContext";
import axios from "axios";
import { ToastContainer } from "react-toastify";

const CSCSCardPage = () => {
  const [cards, setData] = useState([]);
  const { addToCart } = useCart();
  useEffect(() => {
    axios
      .get(`product/filter`, {
        params: {
          filter: "productCategory",
          equal: "660a60318807c436f4492cf3",
        },
      })
      .then((result) => {
        setData(result);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAddToCart = (cards) => {
    addToCart(cards);
  };

  return (
    <div className="bg-gray-50 mt-[100px] md:mt-[150px]">
      {/* Top Heading Section */}
      <div className="text-center py-6 md:py-8 px-4">
        <h1 className="text-2xl md:text-4xl font-bold text-[#FF3131] mb-2">
          CSCS Cards
        </h1>
        <h6 className="text-base md:text-lg text-gray-600">Available Cards</h6>
        <a href="/contact-us">
          {" "}
          <button className="mt-4 px-6 py-2 bg-red-500 text-white rounded-lg">
            Enquire Now
          </button>
        </a>
      </div>

      {/* Dark Box with Two Sections */}
      <div className="flex flex-col lg:flex-row bg-[#0C1524] text-white py-8 md:py-12 px-4 md:px-6 lg:px-12">
        {/* Left Section */}
        <div className="flex-1 lg:pr-12 mb-6 lg:mb-0">
          <h2 className="text-xl md:text-2xl lg:text-3xl font-bold text-[#FF3131] mb-4">
            Benefits of Our CSCS Card Service
          </h2>
          <p className="text-sm md:text-base text-gray-300 leading-relaxed">
            Effortless Application: We take care of the entire application
            process, allowing you to focus on what you do best – your work.
            <br />
            Expert Guidance: Unsure about the right card for your role? Our
            experienced team is here to guide you through the selection process.
            <br />
            Time-Efficient Processing: No more waiting. We expedite the
            application process to get your card to you as quickly as possible.
            <br />
            Peace of Mind: Let professionals handle the paperwork and
            documentation, ensuring accuracy and compliance.
          </p>
        </div>

        {/* Right Section */}
        <div className="relative flex-1 bg-[#FF3131] text-black p-4 md:p-6 lg:p-8 rounded-lg shadow-md lg:transform lg:translate-x-12">
          <div className="flex flex-col items-center justify-center h-full p-4 bg-white lg:transform lg:-translate-x-12 opacity-70 lg:absolute lg:inset-y-0 lg:left-1/3 lg:w-[60%] rounded-lg lg:rounded-none">
            <h3 className="text-lg md:text-xl mb-4 text-center lg:text-left">
              Why Choose Us for Your CSCS Card Application?
            </h3>
            <p className="text-sm md:text-base font-semibold text-center lg:text-left">
              Our streamlined process ensures you enjoy the benefits without the
              hassle.
            </p>
          </div>
        </div>
      </div>

      {/* White Box */}
      <div className="bg-white text-center items-center py-8 px-6 md:px-12 border-2 border-black">
        <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800">
          How It Works
        </h2>
        <p className="text-gray-600 text-base md:text-lg w-full md:w-[70%] mx-auto">
          Place Your Order: Make a secure online payment or Contact Us if you
          need more information before you pay and leave the rest to us. Provide
          Information: Our team of experts will call you to get the required
          information for application. Sit Back and Relax: We handle the entire
          application process, from submission to approval. Receive Your Card:
          Once approved, your CSCS card will be dispatched promptly to your
          designated address. For any questions or assistance, contact us –
          we’re committed to making your construction journey smoother and more
          successful.
        </p>
      </div>

      {/* Dark Box with Cards */}
      <div className="bg-[#0C1524] p-6 md:p-10 rounded-lg z-10 relative mb-16 md:mb-28">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {cards?.data?.result?.map((card, index) => (
            <div
              key={index}
              className="bg-white rounded-t-xl flex flex-col items-stretch text-left">
              {/* Image Section */}
              <img
                src={card?.photo}
                alt={card?.title}
                className=" object-contain rounded-t-xl"
              />

              {/* Card Content */}
              <div className="p-4 flex flex-col flex-grow">
                <h5 className="text-md md:text-lg font-bold mb-2">
                  {card?.title}
                </h5>
                {/* <a href="/" className="text-[#FF3131] underline text-sm mb-2">
                  Card Details
                </a> */}
                <div className="mt-auto flex justify-between items-center">
                  {/* Price */}
                  <p className="text-gray-700 text-base md:text-lg font-semibold">
                    £{card?.price} + VAT
                  </p>

                  {/* Book Now Button */}
                  <button
                    onClick={() => {
                      handleAddToCart(card);
                    }}
                    className="px-4 py-2 bg-red-600 text-white rounded-xl text-sm md:text-base">
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default CSCSCardPage;
