import React, { useState } from "react";

const FAQ = () => {
  const questions = [
    {
      id: "q1",
      question: "Who is this qualification for?",
      answer:
        "This qualification is aimed at professionals in senior health and safety roles, such as health and safety managers or consultants, who are responsible for implementing and managing health and safety systems. ",
    },
    {
      id: "q2",
      question: "What are the entry requirements?",
      answer:
        "Candidates should have experience in managing health and safety systems in the workplace and the ability to provide evidence of their competence in health and safety matters. ",
    },
    {
      id: "q3",
      question: "How is the course delivered?",
      answer:
        " The course is delivered remotely through an online portfolio. You will submit workplace evidence and work closely with your assessor to complete the qualification.",
    },
  ];

  const [activeId, setActiveId] = useState(null);

  const toggleDropdown = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-[#FF3131] mb-6 text-center">
        Quick Questions?
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {questions.map((item) => (
          <div
            key={item.id}
            className={`rounded-2xl shadow-md shadow-[#FF3131]  transition-all overflow-hidden cursor-pointer ${
              activeId === item.id ? "p-4" : "p-4"
            }`}
            onClick={() => toggleDropdown(item.id)}
            style={{
              height: activeId === item.id ? "auto" : "80px", // Fixed height for collapsed cards
            }}>
            {/* Question */}
            <div className="flex justify-between items-center">
              <p className="text-gray-800 font-medium">{item.question}</p>
              <span
                className={`transform transition-transform ${
                  activeId === item.id ? "rotate-180" : ""
                }`}>
                ▼
              </span>
            </div>

            {/* Answer */}
            {activeId === item.id && (
              <div className="mt-2 text-sm text-gray-600">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
