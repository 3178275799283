import React from 'react';

const WhyChooseUs = () => {
  const features = [
    {
      title: 'Fast-Track Your Career',
      description: 'Complete the course in just 1 day and take the first step toward securing your CSCS Green Labourer Card.',
    },
    {
      title: 'Tutor-Led Online Training',
      description: 'Learn from experienced instructors in a live virtual classroom, with direct interaction and support throughout the course.',
    },
    {
      title: 'Nationally Recognized Certification',
      description: 'Earn a ProQual and CITB-accredited qualification, accepted by CSCS, employers, and regulatory bodies in the construction sector.',
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-6 py-12">
      <h2 className="text-4xl font-bold text-center mb-12 text-black">
        Why Choose This Course?
      </h2>
      <div className="flex flex-wrap justify-center gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="w-80 h-80 md:w-72 md:h-72 sm:w-64 sm:h-64 bg-[#18212F] flex flex-col items-center justify-center text-center rounded-full shadow-lg p-6 sm:p-4"
          >
            <h3 className="text-xl md:text-lg sm:text-md font-semibold text-[#FF3131] mb-4">
              {feature.title}
            </h3>
            <p className="text-white text-base md:text-sm sm:text-xs px-4">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
