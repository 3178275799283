import React, { useEffect, useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  add,
  sub,
  isSameMonth,
  isSameDay,
  isWithinInterval,
  parseISO,
} from "date-fns";

const Calendar = ({ scheduleDates }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hasNextMonthSlots, setHasNextMonthSlots] = useState(false);

  // Parse schedule dates safely for Safari compatibility
  const parseDateSafely = (date) =>
    typeof date === "string" ? parseISO(date) : new Date(date);

  useEffect(() => {
    if (scheduleDates && scheduleDates.length > 0) {
      const firstScheduledDate = parseDateSafely(scheduleDates[0]);
      setCurrentDate(firstScheduledDate);
    }
  }, [scheduleDates]);

  useEffect(() => {
    const nextMonthStart = startOfMonth(add(currentDate, { months: 1 }));
    const nextMonthEnd = endOfMonth(nextMonthStart);

    const slotsInNextMonth = scheduleDates.some((scheduledDate) =>
      isWithinInterval(parseDateSafely(scheduledDate), {
        start: nextMonthStart,
        end: nextMonthEnd,
      })
    );

    setHasNextMonthSlots(slotsInNextMonth);
  }, [currentDate, scheduleDates]);

  const getCalendarDates = () => {
    const startDate = startOfWeek(startOfMonth(currentDate));
    const endDate = endOfWeek(endOfMonth(currentDate));

    const dates = [];
    let date = startDate;

    while (date <= endDate) {
      dates.push(date);
      date = add(date, { days: 1 });
    }

    return dates;
  };

  const isScheduledDate = (date) => {
    return scheduleDates.some((scheduledDate) =>
      isSameDay(parseDateSafely(scheduledDate), date)
    );
  };

  const handlePreviousMonth = () =>
    setCurrentDate(sub(currentDate, { months: 1 }));
  const handleNextMonth = () => setCurrentDate(add(currentDate, { months: 1 }));

  const calendarDates = getCalendarDates();

  return (
    <div className="max-w-lg mx-auto p-4 md:p-6 rounded-xl shadow-lg bg-gray-800 text-white">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handlePreviousMonth}
          className="text-xl font-semibold hover:bg-gray-700 p-2 rounded">
          &lt;
        </button>
        <h2 className="text-lg font-bold">
          {format(currentDate, "MMMM yyyy")}
        </h2>
        <button
          onClick={handleNextMonth}
          className={`text-xl font-semibold p-2 rounded ${
            hasNextMonthSlots
              ? "text-[#FF3131] font-bold animate-bounce"
              : "text-white"
          }`}>
          &gt;
        </button>
      </div>

      {/* Day Labels */}
      <div className="grid grid-cols-7 gap-1 md:gap-2 text-center font-medium mb-2">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="text-xs md:text-sm text-gray-400 truncate">
            {day}
          </div>
        ))}
      </div>

      {/* Calendar Grid */}
      <div
        className="grid grid-cols-7 gap-1 md:gap-2 auto-rows-fr"
        style={{ minWidth: 0 }}>
        {calendarDates.map((date, idx) => (
          <div
            key={idx}
            className={`flex items-center justify-center h-10 w-10 rounded-full text-center ${
              isSameMonth(date, currentDate)
                ? isScheduledDate(date)
                  ? "bg-[#FF3131] text-white font-bold"
                  : "text-gray-300"
                : "text-gray-500"
            }`}>
            {format(date, "d")}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
