import React from "react";
import Hero from "./hero";
import WhatsNew from "./whatsNew";
import EmpoweringSuccess from "./EmpoweringSuccess/EmpoweringSuccess";
import Accreditation from "./Accreditations/Accreditations";
import image3 from "../../assets/homePage/logos/Ofqual_logo_2018.svg.png";
import image1 from "../../assets/homePage/logos/m2hse-proqual.jpeg";
import image2 from "../../assets/homePage/logos/citb.jpg";

const images = [image1, image2, image3];

const Home = () => {
  return (
    <div>
      <Hero />
      <WhatsNew />
      <Accreditation images={images} />
      <EmpoweringSuccess />
      {/* <Blogs /> */}
      {/* <ReviewStories /> */}

      <div className=" py-6 px-4 sm:px-8 shadow-lg mx-auto max-w-5xl">
        <h2 className="text-2xl md:text-3xl font-bold text-black mb-4 text-center">
          See What Our Customers Say
        </h2>
        <div
          class="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="54ad5defc6454f065c28af8b"
          data-businessunit-id="65e09121938229973209b5ac"
          data-style-height="240px"
          data-style-width="100%"
          data-stars="1,2,3,4,5"
          data-review-languages="en">
          <a
            href="https://uk.trustpilot.com/review/m2hse.co.uk"
            target="_blank"
            rel="noopener noreferrer">
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
