import React from "react";
import image2 from "../../../assets/Text-Image/image 9.svg";

const EmpoweringSuccess = () => {
  return (
    <section className="bg-[#0C1524] mb-4 text-white py-16 px-6 md:px-20">
      <div className="max-w-7xl mx-auto flex flex-col items-center space-y-12">
        {/* Title and Description */}
        <div className="text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-red-500 mb-4">
            Empowering Your Success
          </h2>
          <p className="text-gray-300 leading-relaxed max-w-3xl mx-auto">
            At M2HSE, we’re committed to providing accredited training and
            innovative solutions that drive growth and efficiency. As a trusted
            partner for professionals and organizations across the UK, we equip
            you with the tools, knowledge, and support needed to achieve
            excellence.
          </p>
        </div>

        {/* Main Content: Left and Right Sections */}
        <div className="flex flex-col md:flex-row items-center justify-between space-y-12 md:space-y-0 md:space-x-8 w-full">
          {/* Left Section */}
          <div className="md:w-1/2 space-y-6 border-l-8 border-l-[#FF3131]">
            <div className="bg-[#1B2438] p-4 rounded-r-xl shadow-md">
              <p>
                <span className="text-red-500 font-semibold">
                  Accredited Training:
                </span>{" "}
                Delivering industry-recognized certifications like CITB courses
                and NVQs to help you stay ahead.
              </p>
            </div>
            <div className="bg-[#1B2438] p-4 rounded-r-xl shadow-md">
              <p>
                <span className="text-red-500 font-semibold">
                  Proven Expertise:
                </span>{" "}
                Trusted by hundreds of businesses and professionals for our
                commitment to quality and results.
              </p>
            </div>
            <div className="bg-[#1B2438] p-4 rounded-r-xl shadow-md">
              <p>
                <span className="text-red-500 font-semibold">Real Impact:</span>{" "}
                98% of our clients report improved performance and compliance
                after working with us.
              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className="md:w-1/2 flex justify-center">
            <div className="max-w-sm md:max-w-md">
              <img
                src={image2}
                alt="Team Working Together"
                className="rounded-xl shadow-lg"
              />
            </div>
          </div>
        </div>

        {/* Footer Text */}
        <p className="text-center italic text-gray-300">
          Join the growing community of satisfied clients who trust us to
          transform their capabilities. Let us empower you every step of the
          way!
        </p>
      </div>
    </section>
  );
};

export default EmpoweringSuccess;
