import React, { useEffect, useState } from "react";
import image1 from "../../../assets/RelatedCourses/m2hse-working-man.svg";
import image2 from "../../../assets/RelatedCourses/m2hse-working-women.svg";
import image3 from "../../../assets/RelatedCourses/m2hse-man-on-site.svg";
import axios from "axios";

const RelatedCourses = () => {
  const [result, setResult] = useState([]);
  const images = [image1, image2, image3];

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const options = {
          q: "NVQ Level 2",
          fields: ["name", "description", "title"],
        };
        let query = "?";
        for (let key in options) {
          query += `${key}=${options[key]}&`;
        }
        query = query.slice(0, -1);

        const response = await axios.get(`product/search${query}`);
        setResult(response.data?.result || []);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };
    fetchSearchResults();
  }, []);

  return (
    <div className="max-w-7xl mx-auto bg-white font-poppins relative px-4 py-10 md:px-10 lg:px-12 xl:px-16 md:mb-2 lg:mb-2">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-black text-center mb-8">
        Related Courses
      </h1>

      {/* Container for the boxes */}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-0 lg:px-6">
        {/* Box 1 */}

        {result.map((data, index) => (
          <div
            onClick={() => {
              window.location.href = data.detailsPage;
            }}
            key={index}
            className="flex flex-col w-full h-auto bg-[#0C1524] rounded-[12px] shadow-md overflow-hidden">
            <img
              src={images[index]}
              alt="Product"
              className="w-full h-48 lg:h-56 object-cover"
            />
            <div className="p-5">
              <h2 className="text-xl lg:text-2xl font-bold text-[#FF3131]">
                {data.title}
              </h2>
              <p className="text-sm text-gray-300 mt-2">{data.name}</p>
              <hr className="my-3 border-gray-300" />
              <p className="text-xl lg:text-2xl font-extrabold text-center text-[#FF3131]">
                £{data.price}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedCourses;
