import React from "react";

const KeyFeaturesBox = () => {
  const features = [
    {
      title: "Skill Validation",
      description:
        "Demonstrates your mastery of Roadbuilding and Maintenance (Construction) - Laying Kerbs and Channels techniques.",
    },
    {
      title: "Real-World Application",
      description:
        "Provides a platform to showcase your on-the-job skills and expertise through practical evidence.",
    },
    {
      title: "Professional Growth",
      description:
        "Enhances your career prospects and establishes your credibility as a skilled professional in Roadbuilding and Maintenance (Construction) - Laying Kerbs and Channels.",
    },
  ];

  return (
    <div className=" py-12 px-4 md:py-16 md:px-12 max-w-7xl mx-auto ">
      {/* Heading */}
      <h2 className="text-center text-2xl md:text-3xl font-semibold mb-10">
        Key Features
      </h2>

      {/* Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg p-6 text-center relative">
            {/* Dark Shape */}
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#0C1524] text-white rounded-tr-3xl  rounded-bl-3xl shadow-slate-600 shadow-xl px-4 py-2 w-10/12">
              <h3 className="font-medium text-base md:text-lg">
                {feature.title}
              </h3>
            </div>
            {/* Content */}
            <div className="mt-12">
              <p className="text-gray-600 text-sm md:text-base">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyFeaturesBox;
