import React, { useEffect, useState } from 'react';
import backgroundImage from "../../../assets/nvq/LevelOne/image.png";
import { useCart } from '../../../CartContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const CourseOverview = () => {

  const { id } = useParams();
    const [slot, setSlot] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { addToCart } = useCart();
    const handleAddToCart = (product) => {
    product.isEmi = false;
    addToCart(product);
  };

  function formatDateRange(startDate, endDate) {
    // Convert the startDate and endDate into Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const startDay = start.getDate().toString().padStart(2, "0");
    const startMonth = monthNames[start.getMonth()];

    const endDay = end.getDate().toString().padStart(2, "0");
    const endMonth = monthNames[end.getMonth()];

    const timeDifference = end - start;
    const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

    const dateRange = `${startDay} ${startMonth} `;

    return { dateRange, days: `${numberOfDays} Days` };
  }

  useEffect(() => {
    const fetchSlot = async () => {
      try {
        const response = await axios.get(`/slots/read/${id}`);
        setSlot(response.data.result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSlot();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <div className="max-w-6xl mx-auto px-6 py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 shadow-lg overflow-hidden">
        
        {/* Left Section */}
        <div className="p-8">
          <h2 className="text-2xl font-bold mb-4">Overview</h2>
          <div className="border-l-4 border-black pl-4 text-gray-700">
            <p>
              This Level 1 Award in Health and Safety in a Construction 
              Environment is designed for individuals seeking the CSCS Green 
              Labourer Card, which allows you to work on construction sites 
              across the UK as a Labourer. This course provides essential 
              health and safety knowledge to ensure compliance with industry 
              standards and legal requirements.
            </p>
          </div>
        </div>

        {/* Right Section */}
        <div className="relative p-8 text-white bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="absolute inset-0 bg-gray-900 opacity-90"></div>
          <div className="relative z-10 space-y-6">
          <div className="flex justify-between pt-4">
              <p className="text-red-500 text-xl font-bold">Date:</p>
              <p className="text-xl  font-bold">
              {formatDateRange(slot.startDate, slot.endDate).dateRange}
              </p>
            </div>

            <div className="flex justify-between pt-4">
              <p className="text-red-500 text-xl font-bold">Duration:</p>
              <p className="text-xl  font-bold">1 Day</p>
            </div>

            <div className="flex justify-between pt-4">
              <p className="text-red-500 text-xl font-bold">Cost:</p>
              <p className="text-xl font-bold">£99 + VAT</p>
            </div>

            <div className="flex justify-between pt-4">
              <p className="text-red-500 text-xl font-bold">Delivery Mode:</p>
              <p className="text-xl font-bold">Tutor-Led Online Classroom </p>
            </div>
            <button onClick={() => handleAddToCart(slot)} className=' mx-[25%] w-1/2 bg-[#FF3131] text-white px-2 py-2 rounded-xl font-bold text-sm' >Add To Cart</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseOverview;
