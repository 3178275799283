import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FiCheckCircle, FiChevronDown, FiChevronUp } from "react-icons/fi";
import citbLogo from "../../../assets/CitbPage/citb_logo.png";
import sectionImage from "../../../assets/CitbPage/image 11.svg";
import WhyChooseCitb from "./WhyToChooseSmstsRefresher";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useCart } from "../../../CartContext";
import { RxCalendar } from "react-icons/rx";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoMdPeople } from "react-icons/io";
import TrustPilot from "../TrustPilot/TrustPilot";
import ContactPageModal from "../../Contact_Us/CITBContactForm/ContactFormCitb";
import TrustpilotWidget from "../TrustPilot/TrustPilot";
import Calendar from "../Calender/Calendar";

const SmstsRefresherCourseDetail = () => {
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false);
  const { id } = useParams();
  const [slot, setSlot] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addToCart } = useCart();

  function formatDateRange(startDate, endDate) {
    // Convert the startDate and endDate into Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const startDay = start.getDate().toString().padStart(2, "0");
    const startMonth = monthNames[start.getMonth()];

    const endDay = end.getDate().toString().padStart(2, "0");
    const endMonth = monthNames[end.getMonth()];

    const timeDifference = end - start;
    const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

    const dateRange = ` ${startDay}-${startMonth} and ${endDay}-${endMonth}`;

    return { dateRange, days: `${numberOfDays} Days` };
  }

  useEffect(() => {
    const fetchSlot = async () => {
      try {
        const response = await axios.get(`/slots/read/${id}`);
        setSlot(response.data.result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSlot();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const getTimeFromDate = (dateStr) => {
    const date = new Date(dateStr);

    // Format for UK time
    return date.toLocaleTimeString("en-GB", {
      timeZone: "Europe/London", // Ensure it converts to UK time
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Use 24-hour format; set to true for 12-hour format
    });
  };

  const handleAddToCart = (product) => {
    product.isEmi = false;
    addToCart(product);
  };
  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="mt-32 max-w-5xl m-auto mx-auto p-4 md:p-10 font-sans bg-gray-50">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-6 mb-8">
          <div className="text-center md:text-left w-full md:w-2/3">
            <img
              src={citbLogo}
              alt="CITB Logo"
              className="w-[150px] mx-auto md:mx-0 h-auto mt-4 mb-6"
            />
            <h1 className="text-3xl md:text-4xl font-bold text-[#FF3131] mb-2">
              SMSTS Refresher Course
            </h1>
            <p className="text-lg text-gray-700">
              The SMSTS Refresher course is a 2-day site management training
              course.
            </p>
          </div>
          <div className="flex flex-col gap-2 items-center w-full md:w-1/3">
            <ContactPageModal />
            <TrustpilotWidget
              templateId={"53aa8807dec7e10d38f59f32"}
              businessUnitId={"65e09121938229973209b5ac"}
            />
          </div>
        </div>

        {/* Card Section */}
        <div className="bg-[#0C1524] text-white rounded-xl max-w-4xl m-auto p-6 flex flex-col lg:flex-row gap-6 items-center shadow-lg">
          {/* Left Content */}
          <div className="w-full lg:w-1/2">
            <h2 className="text-2xl mb-2 font-bold text-[#FF3131]">
              £{slot.product.price} + VAT
            </h2>
            <div className="space-y-4 text-lg">
              {/* Date Range */}
              <div className="flex items-center">
                <RxCalendar className="mr-2 text-white" size={20} />
                <span className="font-semibold">
                  {formatDateRange(slot.startDate, slot.endDate).dateRange}
                </span>
              </div>

              {/* Slot Type */}
              <div className="flex items-center">
                <IoLocationOutline className="mr-2 text-white" size={20} />
                <span className="font-semibold">{slot.type}</span>
              </div>

              {/* Start Time */}
              <div className="flex items-center">
                <AiOutlineClockCircle className="mr-2 text-white" size={20} />
                <span className="font-semibold">
                  {getTimeFromDate(slot.startDate)} to {getTimeFromDate(slot.endDate)} 
                </span>
              </div>

              {/* Remaining Slots */}
              <div className="flex items-center">
                <IoMdPeople className="mr-2 text-white" size={20} />
                <span className="font-semibold">{slot.seatsNo} slots left</span>
              </div>
            </div>
            <button
              onClick={() => handleAddToCart(slot)}
              className="mt-4 bg-[#FF3131] text-white font-semibold py-2 px-6 rounded hover:bg-[#FF4D4D] transition duration-300">
              Add to Cart
            </button>
          </div>
          {/* Right Image */}
          <div className="w-full lg:w-1/2">
            <Calendar
              className="rounded-lg w-full"
              scheduleDates={slot.scheduleDates}
            />{" "}
          </div>
        </div>

        {/* Accordion Sections */}
        <div className="w-full max-w-4xl m-auto mt-10">
          {/* How It Works */}
          <div className="border-b py-4 bg-white rounded-lg shadow-sm mb-4">
            <button
              onClick={() => setHowItWorksOpen(!howItWorksOpen)}
              className="flex justify-between items-center w-full text-left font-semibold text-gray-800 text-lg px-4 py-2 hover:bg-gray-100 rounded transition">
              How It Works
              {howItWorksOpen ? <FiChevronUp /> : <FiChevronDown />}
            </button>
            <div
              className={`overflow-auto transition-all duration-500 ${
                howItWorksOpen ? "max-h-screen" : "max-h-0"
              }`}>
              <div className="px-4 py-2 text-gray-600">
                <h2 className="font-bold mb-2 text-black">
                  Booking Confirmation
                </h2>
                <p>
                  Once you book your spot, you’ll receive a confirmation email
                  securing your place on the course. Ensure you have sufficient
                  IT skills and access to a Laptop/PC/Mac with a
                  working webcam and microphone.
                </p>

                <h2 className="font-bold mb-2 text-black">
                  Course Information and Resources
                </h2>
                <ul className="list-disc ml-4 mb-4">
                  <li>
                    <strong>2 days before the course</strong> (or immediately
                    upon booking if the course is less than 2 days away), you
                    will receive:
                    <ul className="list-disc ml-6">
                      <li>The ZOOM link to join the training sessions.</li>
                      <li>Further instructions about the course.</li>
                    </ul>
                  </li>
                  <li>
                    Access to your online e-book resources to help you prepare
                    for the course.
                  </li>
                </ul>

                <h2 className="font-bold mb-2 text-black">
                  Mandatory Online Form
                </h2>
                <p>
                  You will receive an email with a link to a mandatory online
                  form that must be completed before the course starts. Failure
                  to complete the form may result in being unable to participate
                  in the course.
                </p>

                <h2 className="font-bold mb-2 text-black">
                  Punctuality and Course Start
                </h2>
                <p>
                  The course starts promptly at <strong>8:30 AM</strong> each
                  day. Late arrivals may not be allowed to complete the course,
                  as per CITB rules.
                </p>

                <h2 className="font-bold mb-2 text-black">
                  Identification Requirements
                </h2>
                <p>
                  Ensure you bring a valid ID (passport or driving license) to
                  verify your identity at the start of the course.
                </p>

                <h2 className="font-bold mb-2 text-black">
                  Attendance and Exam
                </h2>
                <ul className="list-disc ml-4 mb-4">
                  <li>
                    Full attendance on both days of the course is mandatory.
                  </li>
                  <li>
                    You must complete the final exam at the end of the course to
                    earn your certification.
                  </li>
                </ul>

                <h2 className="font-bold mb-2 text-black">
                  Receiving Your Certificate
                </h2>
                <ul className="list-disc ml-4">
                  <li>
                    You will receive your result confirmation via email{" "}
                    <strong>1 day</strong> after completing the course.
                  </li>
                  <li>
                    Your CITB-accredited certificate will be sent via email{" "}
                    <strong>14-21 working days</strong> after your results are
                    submitted to CITB.
                  </li>
                  <li>
                    Please keep this timeline in mind when planning employment
                    start dates or other commitments.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Additional Information */}
          <div className="border-b py-4 bg-white rounded-lg shadow-sm">
            <button
              onClick={() => setAdditionalInfoOpen(!additionalInfoOpen)}
              className="flex justify-between items-center w-full text-left font-semibold text-gray-800 text-lg px-4 py-2 hover:bg-gray-100 rounded transition">
              Additional Information
              {additionalInfoOpen ? <FiChevronUp /> : <FiChevronDown />}
            </button>
            <div
              className={`overflow-hidden transition-all duration-500 ${
                additionalInfoOpen ? "max-h-screen" : "max-h-0"
              }`}>
              <div className="px-4 py-2 text-gray-600">
                <h2 className="font-bold mb-1 text-black">
                  Language Requirements:
                </h2>
                <p> You must be able to speak, read, and write in English</p>
                <h2 className="font-bold mb-1 text-black">
                  Internet Connection:
                </h2>
                <p>
                  A stable internet connection that supports audio and video
                  conferencing is mandatory for online sessions.
                </p>
              </div>
              <p className="p-2">
                By following these steps, you’ll ensure a smooth process from
                booking to completing your CITB SMSTS Refresher course and
                receiving your certification. Enroll today to secure your spot!{" "}
              </p>
            </div>
          </div>
        </div>

        {/* Course Details Section */}
        <div className="mt-10 flex flex-col md:flex-row gap-8">
          {/* Left Side: Course Details */}
          <div className="flex-[0.30]">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Course Details
            </h2>
            <p className="text-gray-600">
              The CITB SMSTS Refresher course is designed for site managers who
              have previously completed the full CITB SMSTS course. This
              refresher ensures that participants stay up-to-date with the
              latest health and safety regulations, industry standards, and best
              practices in site management.
            </p>
          </div>

          {/* Vertical HR */}
          <div className="hidden md:block border-l border-gray-300"></div>

          {/* Right Side: Key Elements */}
          <div className="flex-[0.70]">
            <h3 className="text-xl font-semibold mb-4">
              The course covers the following key areas:
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {[
                "Latest Updates in Health and Safety Legislation: A review of changes to the Health and Safety at Work Act and other relevant regulations, including updates on industry guidance and practices.",
                "Reinforcement of Management Responsibilities: A recap of the roles and responsibilities of site managers in maintaining a safe and compliant work environment.",
                "Revisiting Accident Prevention and Reporting: Updated guidance on preventing workplace incidents, managing risks, and ensuring proper reporting and investigation processes.",
                "Refresher on Risk Assessments and Method Statements: A detailed review of creating effective risk assessments, method statements, and control measures for maintaining site safety.",
                "Environmental Awareness Updates: Insight into new environmental regulations, sustainable practices, and waste management compliance.",
              ].map((item, index) => (
                <div
                  key={index}
                  className="bg-[#0C1524] text-white p-4 rounded-xl hover:scale-105 transition-transform duration-300">
                  {item}
                </div>
              ))}
            </div>
            <p className="text-gray-600 mt-4">
              Note: Attendance for both days is mandatory. Participants will be
              assessed continuously during the course and must pass the final
              exam to renew their certification.
            </p>
          </div>
        </div>

        {/* Why Choose SMSTS */}
        <div className="mt-10">
          <WhyChooseCitb />
        </div>

        {/* What's Included */}
        <div className="mt-10 bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            What’s Included
          </h2>
          <div className="space-y-4">
            <div className="flex items-start space-x-4">
              <div className="text-green-500">
                <FiCheckCircle size={24} />
              </div>
              <div className="text-gray-600">
                <p>Live training with CITB-approved industry professionals</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="text-green-500">
                <FiCheckCircle size={24} />
              </div>
              <div className="text-gray-600">
                <p>All course materials (provided digitally)</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="text-green-500">
                <FiCheckCircle size={24} />
              </div>
              <div className="text-gray-600">
                <p>Certificate upon successful completion</p>
              </div>
            </div>
          </div>
          <p className="text-gray-600 mt-6">
            Keep your qualifications current and maintain your competitive edge
            in site management—enroll in our CITB SMSTS Refresher training
            today!
          </p>
        </div>

        {/* Footer */}
        <div className="mt-10 bg-gray-200 text-center p-6 rounded-lg shadow">
          <h2 className="text-2xl font-bold text-[#FF3131] mb-4">
            Completing the Course
          </h2>
          <p className="text-gray-800 text-lg mb-4">
            Successful candidates will receive the CITB SMSTS Refresher
            certificate, awarded upon completing the course and passing the
            final exam.
          </p>
          <ul className="text-gray-800 text-lg space-y-2">
            <li>
              <strong>Recognized Nationwide:</strong> Ensures you meet the
              mandatory requirements for site management roles.
            </li>
            <li>
              <strong>Valid for 5 Years:</strong> Demonstrates your ongoing
              commitment to health and safety excellence.
            </li>
          </ul>
          <p className="text-gray-800 text-lg mt-4">
            By completing this course, you’ll ensure your knowledge and
            credentials are up-to-date, allowing you to continue managing
            construction sites effectively and safely.
          </p>
        </div>
      </div>
    </>
  );
};

export default SmstsRefresherCourseDetail;
