import React from "react";

const HowItWorks = () => {
  const steps = [
    {
      title: "Flexible, Remote Learning",
      description:
        "Complete the course at your own pace using an online portfolio.",
    },
    {
      title: "Evidence-Based Assessment",
      description:
        "Submit evidence from your current role to demonstrate your skills and knowledge.",
    },
    {
      title: "Customized Assessment Plan",
      description:
        "Start with a tailored plan that aligns with your experience and schedule, allowing you to complete the course in 6-8 weeks.",
    },
    {
      title: "Dedicated Assessor Support",
      description:
        "Work closely with an experienced assessor who will provide feedback and guidance throughout the process.",
    },
  ];

  return (
    <div className=" py-12 px-6 p-6 md:p-10 max-w-6xl mx-auto md:px-12">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
        How It Works
      </h2>

      {/* Titles, Line, and Red Dots */}
      <div className="mb-12">
        <div className="flex justify-between items-center relative">
          {/* Titles Above Dots */}
          {steps.map((step, index) => (
            <div
              key={index}
              className="text-center flex justify-between items-center w-1/4">
              <h3 className="text-md md:text-lg w-3/4 font-bold mb-4">
                {step.title}
              </h3>
            </div>
          ))}
        </div>

        {/* Line with Dots */}
        <div className="relative flex justify-between items-center">
          {/* Horizontal Line */}
          <div className="absolute top-1/2 w-full h-1 bg-black transform -translate-y-1/2 z-0"></div>
          {/* Red Dots */}
          {steps.map((_, index) => (
            <div
              key={index}
              className="z-10 w-6 h-6 bg-[#FF3131] rounded-full flex items-center justify-center"></div>
          ))}
        </div>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {steps.map((step, index) => (
          <div
            key={index}
            className="p-6 bg-[#0C1524] text-white rounded-xl shadow-inner shadow-white text-center flex flex-col justify-center items-center">
            <p className="text-sm md:text-base leading-relaxed">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
