import React from "react";

const Accreditation = ({ images }) => {
  return (
    <section className="p-10">
      <div className="flex items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 border-b-4 border-red-600 pb-2 pr-6">
          Our Accreditations
        </h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {images.map((image, index) => (
          <div key={index} className="flex justify-center">
            <img
              src={image}
              alt={`Accreditation ${index + 1}`}
              className="w-[300px] h-[200px] object-contain"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Accreditation;
