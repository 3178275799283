import React, { useState } from "react";

const FaqAndContact = () => {
  const faqs = [
    {
      question: "What payment methods do you accept for payment plans?",
      answer:
        "We accept credit/debit cards, PayPal, GooglePay, ApplePay, and bank transfers for payment plans.",
    },
    {
      question: "How do I sign up for a payment plan?",
      answer:
        "You can sign up for a payment plan during the checkout process. Simply choose your preferred plan and add it to the cart, and you'll be guided through the setup.",
    },
    {
      question: "Is there a down payment required to start a payment plan?",
      answer:
        "Yes, the first payment is required upfront to activate the payment plan.",
    },
    {
      question: "Can I choose the duration of my payment plan?",
      answer:
        "Our payment plans offer fixed options: 2, 3, or 6 installments over a 6-month period.",
    },
    {
      question:
        "Are there any additional fees or interest with the payment plan?",
      answer:
        "No, there are no additional fees or interest. All plans are at no extra cost.",
    },
    {
      question: "What happens if I miss a payment?",
      answer:
        "If you miss a payment, you will be notified, and we will work with you to resolve the issue. Please note that reasonable delays in payments may delay the completion of your NVQ.",
    },
    {
      question: "Can I pay off the remaining balance early without penalties?",
      answer:
        "Yes, you can pay off the remaining balance at any time without any penalties.",
    },
    {
      question: "How do I update my payment method or billing information?",
      answer:
        "You can update your payment method or billing information by contacting our support team.",
    },
    {
      question: "Will I receive a reminder before each instalment is due?",
      answer:
        "Yes, you will receive a reminder before each instalment is due, ensuring you’re prepared for upcoming payments.",
    },
    {
      question: "What happens if I want to cancel my payment plan?",
      answer:
        "If you wish to cancel your payment plan, please contact us. However, the balance of the amount will still need to be settled based on the agreement.",
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const toggleFaq = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.email) newErrors.email = "Email is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      alert("Form submitted successfully!");
      // Handle form submission logic here
    }
  };

  return (
    <div className="bg-[#0C1524] text-white p-8 md:p-12 lg:px-20 lg:py-16">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* FAQ Section */}
        <div>
          <h2 className="text-2xl md:text-3xl text-[#FF3131] font-semibold mb-6">
            FAQ'S
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-[#1B2438] p-4 rounded-xl shadow-inner shadow-gray-500">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFaq(index)}>
                  <h3 className="text-lg font-medium"> {faq.question}</h3>
                  <span>{expandedIndex === index ? "-" : "+"}</span>
                </div>
                {expandedIndex === index && (
                  <p className="mt-4 text-gray-300">Ans: {faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Contact Form Section */}
        <div>
          <h2 className="text-2xl md:text-3xl font-semibold mb-6 ">
            Get in Touch
          </h2>
          <form
            onSubmit={handleSubmit}
            className="space-y-4  bg-[#1B2438] rounded-xl shadow-inner shadow-gray-500 p-6">
            {/* Name */}
            <div>
              <label className="block text-sm mb-2" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your full name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 bg-[#1B2438] border border-gray-600 rounded-xl text-white"
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">{errors.name}</p>
              )}
            </div>

            {/* Phone */}
            <div>
              <label className="block text-sm mb-2" htmlFor="phone">
                Phone
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Your phone number"
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-3 bg-[#1B2438]  border border-gray-600 rounded-xl text-white"
              />
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
              )}
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Your email id"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 bg-[#1B2438] border border-gray-600 rounded-xl text-white"
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>

            {/* Message */}
            <div>
              <label className="block text-sm mb-2" htmlFor="message">
                Message (Optional)
              </label>
              <textarea
                name="message"
                id="message"
                placeholder="Please drop your query, we would love to help you"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-3 bg-[#1B2438]  border border-gray-600 rounded-xl text-white"
                rows="4"></textarea>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-[#188E00] shadow-inner shadow-white hover:bg-green-900 text-white py-3 rounded-xl">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FaqAndContact;
