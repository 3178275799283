import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 6 NVQ Diploma in Senior Site Inspection validates your
        expertise in monitoring site quality, managing project progress, and
        ensuring compliance with industry standards. It equips you with the
        skills and knowledge needed to operate effectively as a senior site
        inspector or construction quality manager.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        Assessment Contents & Units
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>Monitor project quality in construction</li>
                <li>Practice in a professional manner in construction</li>
                <li>Manage interaction with others in construction</li>
                <li>
                  Set up and manage technical information systems in
                  construction
                </li>
                <li>
                  Verify and maintain systems for managing site health, safety,
                  and welfare in construction
                </li>
                <li>Manage project progress and handover in construction</li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units: one unit from Optional Group A, plus one unit from
            Optional Group B <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Group A - ONE unit required
                  <ul>
                    <li>
                      Implement agreed policies and programmes for undertaking
                      maintenance works in construction
                    </li>
                    <li>
                      Confirm and report on property condition in construction
                    </li>
                    <li>Monitor test processes in construction</li>
                  </ul>
                </li>
                <li>
                  Group B - ONE unit required
                  <ul className="list-disc list-inside">
                    <li>
                      Manage and develop teams and individuals in construction
                    </li>
                    <li>Recruit and plan the workforce in construction</li>
                    <li>
                      Evaluate and progress the resolution of potential disputes
                      in construction
                    </li>
                    <li>
                      Monitor and solve customer service problems in
                      construction
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
