import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 2 NVQ Certificate in Plant Operations (Construction) -
        Attachments is tailored for individuals working within Plant Operations
        (Construction) - Attachments in construction. This qualification
        demonstrates your ability to apply your skills in Plant Operations
        (Construction) - Attachments, ensuring compliance with health and safety
        standards and legal requirements.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Conforming to General Health, Safety, and Welfare in the
                  Workplace
                </li>
                <li>
                  Conforming to Productive Working Practices in the Workplace{" "}
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units: one optional unit is required
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Preparing and operating excavator cranes to lift and transfer
                  loads in the workplace
                </li>
                <li>
                  Preparing and operating rough terrain masted forklifts to lift
                  and transfer loads in the workplace
                </li>
                <li>
                  Preparing and operating telescopic handlers to lift and
                  transfer loads in the workplace
                </li>
                <li>
                  Preparing and operating lorry loaders or knuckle booms to lift
                  and transfer loads in the workplace
                </li>
                <li>
                  Preparing and operating wheeled loading shovels to extract
                  loose materials in the workplace
                </li>
                <li>
                  Preparing and operating skid steer loaders to extract loose
                  materials in the workplace
                </li>
                <li>
                  Preparing and operating loader compressors to extract loose
                  materials in the workplace
                </li>
                <li>
                  Preparing and operating forward tipping dumpers to receive,
                  transport and discharge materials in the workplace
                </li>
                <li>
                  Preparing and operating rear tipping dump trucks to receive,
                  transport and discharge materials in the workplace
                </li>
                <li>
                  Preparing and operating tractors with towed equipment for
                  non-agricultural activities in the workplace
                </li>
                <li>
                  Preparing and operating scissor-type mobile elevating work
                  platforms (MEWP) in the workplace
                </li>
                <li>
                  Preparing and operating boom-type mobile elevating work
                  platforms (MEWP) in the workplace
                </li>
                <li>
                  Preparing and operating mast climber-type mobile elevating
                  work platforms (MEWP) in the workplace
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
