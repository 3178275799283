import React from "react";
import backgroundImage from "../../../assets/homePage/m2hse-constructiongirl-backgroundImage.svg";
import icon1 from "../../../assets/nvq/heritagePainter/m2hse-cap.png";
import icon3 from "../../../assets/nvq/heritagePainter/m2hse-card-Icon.png";
import icon2 from "../../../assets/nvq/heritagePainter/m2hse-user-icon.png";
const Hero = () => {
  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Overlay */}
      <div className="absolute inset-0 bg-gray-950 opacity-80"></div>

      {/* Content */}
      <div className="relative min-h-[550px] flex flex-col justify-center px-4 sm:px-6 lg:px-20">
        <div className="max-w-6xl mx-auto text-center">
          {/* Main Heading */}
          <h4 className="text-white text-lg sm:mt-10 mt-24 sm:text-xl">
            Level 7 NVQ Diploma in
          </h4>
          <h1 className="text-[#FF3131] text-3xl sm:text-5xl font-bold mt-2">
            Construction Senior Management
          </h1>

          {/* TrustBox Widget */}
          <div
            className="trustpilot-widget my-4"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="65e09121938229973209b5ac"
            data-style-height="120px"
            data-style-width="100%"
            data-theme="dark">
            <a
              href="https://uk.trustpilot.com/review/m2hse.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white">
              Trustpilot
            </a>
          </div>

          {/* Enquire Now */}
          <a href="/contact-us">
            <button className="bg-[#FF3131] text-white py-3 px-8 rounded-lg text-base sm:text-lg font-medium hover:bg-[#ff4d4d] transition-all ">
              Enquire Now
            </button>
          </a>
        </div>
      </div>

      {/* Cards Section */}
      <div className="relative px-4 sm:px-6 lg:px-20 py-8 rounded-t-3xl -mt-16 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Card 1 */}
          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
            <div className="mb-6">
              <img src={icon1} alt="Icon 1" className="h-12 w-12" />
            </div>
            <p className="text-center text-sm md:text-base">
              Finish Your Qualification in <br />
              <span className="text-[#FF3131] font-semibold">8–12 Weeks</span>
            </p>
          </div>

          {/* Card 2 */}
          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
            <div className="mb-6">
              <img src={icon2} alt="Icon 2" className="h-12 w-12" />
            </div>
            <p className="text-center text-sm md:text-base">
              Apply for a <br />
              <span className="text-[#FF3131] font-semibold">
                Black CSCS Card
              </span>{" "}
              <br />
              After Completion
            </p>
          </div>

          {/* Card 3 */}
          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
            <div className="mb-6">
              <img src={icon3} alt="Icon 3" className="h-12 w-12" />
            </div>
            <p className="text-center text-sm md:text-base">
              Ofqual & CITB <br />
              <span className="text-[#FF3131] font-semibold">
                Approved
              </span>{" "}
              <br />
              <span className="text-[#FF3131] font-semibold">
                Qualification
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
