import React, { useState } from "react";

const FAQ = () => {
  const questions = [
    {
      id: "q1",
      question: "Who is this qualification for?",
      answer:
        "This NVQ is designed for senior professionals in construction management, such as project managers, construction directors, and senior executives, who want formal recognition of their leadership and project management skills.  ",
    },
    {
      id: "q2",
      question: "What are the entry requirements?",
      answer:
        "Candidates must have significant experience in construction management and the ability to provide workplace evidence of their expertise in project control and team leadership.",
    },
    {
      id: "q3",
      question: "How is the course delivered?",
      answer:
        " The course is delivered remotely through evidence submission and an online portfolio, allowing for flexible learning that fits your schedule. ",
    },
    {
      id: "q4",
      question: "Can I gain MCIOB with this qualification?",
      answer:
        " You will be eligible to gain MCIOB via fast track route. All our NVQ L7 assessors are chartered members with CIOB and will guide and sign off your CIOB application.  ",
    },
    {
      id: "q5",
      question: "Can our assessors sign your MCIOB application?",
      answer:
        "All M2HSE L7 Assessors are charted members with CIOB and will guide and sign off your CIOB application.  ",
    },
  ];

  const [activeId, setActiveId] = useState(null);

  const toggleDropdown = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-[#FF3131] mb-6 text-center">
        Quick Questions?
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {questions.map((item) => (
          <div
            key={item.id}
            className={`rounded-2xl shadow-md shadow-[#FF3131]  transition-all overflow-hidden cursor-pointer ${
              activeId === item.id ? "p-4" : "p-4"
            }`}
            onClick={() => toggleDropdown(item.id)}
            style={{
              height: activeId === item.id ? "auto" : "80px", // Fixed height for collapsed cards
            }}>
            {/* Question */}
            <div className="flex justify-between items-center">
              <p className="text-gray-800 font-medium">{item.question}</p>
              <span
                className={`transform transition-transform ${
                  activeId === item.id ? "rotate-180" : ""
                }`}>
                ▼
              </span>
            </div>

            {/* Answer */}
            {activeId === item.id && (
              <div className="mt-2 text-sm text-gray-600">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
