import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 6 NVQ Diploma in Occupational Health and Safety Practice is
        designed to showcase your ability to manage health and safety systems,
        develop policies, and monitor practices to ensure compliance and improve
        safety performance. This qualification is particularly suited for
        individuals working in senior health and safety roles, focusing on
        high-level strategic and operational safety management.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        Assessment Contents & Units
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>Promote a Positive Health and Safety Culture</li>
                <li>Develop and Implement the Health and Safety Policy</li>
                <li>
                  Develop and Implement Effective Communication Systems for
                  Health and Safety Information
                </li>
                <li>
                  Develop and maintain individual and organisational competence
                  in health and safety matters
                </li>
                <li>Identify, assess and control health and safety risks</li>
                <li>
                  Develop and implement proactive monitoring systems for health
                  and safety
                </li>
                <li>
                  Develop and implement reactive monitoring systems for health
                  and safety{" "}
                </li>
                <li>
                  Develop and implement health and safety emergency response
                  systems and procedures{" "}
                </li>
                <li>Develop and implement health and safety review systems</li>
                <li>
                  Maintain knowledge of improvements to influence health and
                  safety practice
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units: a minimum of 620 TQT required
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Direct the Management of Design Development and Processes in
                  Construction Management – 220TQT{" "}
                </li>
                <li>
                  Prepare and Agree a Project Brief and Outline Programme in
                  Construction Management – 240 TQT{" "}
                </li>
                <li>
                  Sustainability and Environmental Impact of Developments in
                  Construction Management – 200 TQT
                </li>
                <li>
                  Evaluate Sustainable Resources and Requirements for the Whole
                  Lifecycle of a Construction Project – 170 TQT{" "}
                </li>
                <li>
                  Establish Project Procurement Arrangements in Construction
                  Management – 180 TQT
                </li>
                <li>
                  Ensure That Contracts Are Prepared, Negotiated and Agreed in
                  Construction Management – 180 TQT{" "}
                </li>
                <li>
                  Evaluate and Progress the Resolution of Contractual Disputes
                  in Construction Management – 180 TQT{" "}
                </li>
                <li>
                  Manage Tendering Processes in Construction Management – 190
                  TQT
                </li>
                <li>
                  Manage the Preparation and Submission of Estimates, Bids and
                  Tenders in Construction Management – 160 TQT{" "}
                </li>
                <li>
                  Control Budgets and Contract Payment Entitlement in
                  Construction Management – 110 TQT
                </li>
                <li>
                  Manage Marketing and Customer Service in Construction
                  Management – 130 TQT{" "}
                </li>
                <li>
                  Manage the Handover of the Construction Project in the
                  Workplace – 100 TQT{" "}
                </li>
                <li>
                  Implement, Monitor and Control Strategic Procurement Systems
                  in Construction Management – 120 TQT{" "}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
