import React from 'react';

const KeyFeatures = () => {
  const features = [
    {
      id: 1,
      title: 'CSCS Green Labourer Card Eligibility',
      description:
        'Successfully completing this course qualifies you to apply for the Green CSCS Card, a requirement for entry-level roles on construction sites.',
    },
    {
      id: 2,
      title: 'Accessible to Everyone',
      description:
        'No Entry Requirements: Open to individuals of all experience levels. Ideal for those starting a career in construction or looking to improve employability.',
    },
    {
      id: 3,
      title: 'Comprehensive and Flexible Learning',
      description:
        'Delivered remotely via an interactive, tutor-led online classroom. Includes a workbook-based assessment to demonstrate your knowledge and competency.',
    },
    {
      id: 4,
      title: 'Career Growth Opportunities',
      description:
        'Enhance your credentials and open doors to roles in the construction industry. Gain foundational health and safety knowledge applicable across various site environments.',
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-6 py-12">
      <h2 className="text-4xl font-bold text-center mb-12 text-[#18212F]">
        Key Features
      </h2>
      <div className="grid md:grid-cols-2 gap-8">
        {features.map((feature) => (
          <div
            key={feature.id}
            className="bg-white p-6 shadow-lg border-l-8 border-b-8 border-gray-200"
          >
            <h3 className="text-xl font-bold text-[#18212F] mb-2">
              {feature.id}. {feature.title}
            </h3>
            <p className="text-gray-600 text-lg">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyFeatures;
