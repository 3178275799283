import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    axios
      .get(`/session-status`, {
        params: { session_id: sessionId }, // Passing query parameters
      })
      .then((response) => {
        const data = response.data;
        // console.log(data); // Assuming the API response structure is { success: true, status: "open" | "complete", customer_email: "example@example.com" }
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  if (status === "open") {
    return <Navigate to="/stripe-checkout" />;
  }

  if (status === "complete") {
    return (
      <section
        id="success"
        className="flex justify-center items-center min-h-screen px-4">
        <div className="bg-white shadow-lg rounded-2xl p-8 max-w-md w-full transform hover:scale-[1.02] transition-all duration-300">
          <div className="relative">
            {/* Subtle Glow Effect */}
            <div className="absolute inset-0 bg-gradient-to-b from-[#FF3131] to-[#FF6666] opacity-10 blur-xl rounded-2xl"></div>
          </div>

          {/* Content */}
          <h2 className="text-center text-2xl sm:text-3xl font-bold text-gray-800 mb-4">
            🎉 Thank You for Your Purchase!
          </h2>
          <p className="text-gray-600 text-center text-base sm:text-lg">
            We wish to see you again! A confirmation email will be sent to{" "}
            <span className="font-semibold text-gray-900">{customerEmail}</span>
            . If you have any questions, please email{" "}
            <a
              href="mailto:office@m2hse.co.uk"
              className="text-[#FF3131] font-semibold hover:underline">
              office@m2hse.co.uk{" "}
            </a>
            .
          </p>
        </div>
      </section>
    );
  }

  return null;
};

export default Return;
