import React from 'react';
import { FaCheckCircle, FaRegClock, FaLaptop, FaComments, FaBookOpen, FaCertificate } from 'react-icons/fa';

const CourseSteps = () => {
  const steps = [
    {
      icon: <FaRegClock size={30} className="text-red-500" />,
      title: 'Enroll Online',
      description: 'Click “Add To Cart” and complete secure online payment.',
    },
    {
      icon: <FaLaptop size={30} className="text-red-500" />,
      title: 'Receive Course Details',
      description: 'After enrollment, you’ll receive an email with the link to join the course and joining instructions.',
    },
    {
      icon: <FaComments size={30} className="text-red-500" />,
      title: 'Join the Course',
      description: 'On the scheduled date, use the link provided to join the live, tutor-led online session.',
    },
    {
      icon: <FaBookOpen size={30} className="text-red-500" />,
      title: 'Interactive Learning',
      description: 'Engage in live discussions and gain practical insights from expert instructors throughout the session.',
    },
    {
      icon: <FaLaptop size={30} className="text-red-500" />,
      title: 'Complete Your Workbook',
      description: 'Complete exercises to reinforce learning and prepare for the final assessment.',
    },
    {
      icon: <FaCertificate size={30} className="text-red-500" />,
      title: 'Receive Your Certificate',
      description: 'Upon successful completion, you will receive your certificate within 7 business days.',
    },
  ];

  return (
    <div className="max-w-6xl mx-auto px-6 py-12">
      <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">How It Works</h2>
      <div className="relative border-l-4 border-red-500 pl-8 space-y-12">
        {steps.map((step, index) => (
          <div key={index} className="flex items-start group">
            <div className="absolute -left-6 flex items-center justify-center w-12 h-12 bg-white border-4 border-red-500 rounded-full shadow-lg">
              {step.icon}
            </div>
            <div className="bg-gray-50 group-hover:bg-white transition-all shadow-lg rounded-lg p-6 w-full">
              <h3 className="text-xl font-semibold text-gray-900">{step.title}</h3>
              <p className="text-gray-600 mt-2">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseSteps;
