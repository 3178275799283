import { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const WhyChooseM2HSECarousel = () => {
  const points = [
    "Tailored Learning Experience: Our programs are designed to fit around your schedule, with flexible online learning options.",
    "Expert Trainers: Learn from industry professionals with real-world experience in construction management and health & safety.",
    "Accredited Qualifications: All courses are accredited by recognized industry bodies like CITB, ensuring your certification is highly valued.",
    "Fast-Track Completion: Get qualified in as little as 6-12 weeks with our fast-track options.",
    "Career Advancement: Our qualifications open doors to higher positions such as Site Manager or Health & Safety Officer.",
    "100% Online: Complete the entire course online without needing to take time off from work.",
    "Dedicated Support: Enjoy one-on-one support from experienced assessors throughout your NVQ journey.",
    "Proven Success: Our track record speaks for itself, with hundreds of professionals advancing their careers through our qualifications",
    "Payment Plans: Flexible payment options available to help you spread the cost.",
    "No Exams, Only Assessments: Achieve your qualification through practical work assessments without the stress of traditional exams",
    "Recognized Pathway to CSCS Cards: Our qualifications lead to eligibility for various CSCS Cards, including the prestigious Black Card.",
    "Industry Partnerships: We collaborate with top industry bodies and employers to ensure our courses meet the latest standards.",
    "Lifelong Certification: Earn a qualification that will stay with you throughout your professional career.",
    "Customizable Learning Plans: Tailor your learning experience to your specific role and industry needs.",
    "Real-World Application: Our courses focus on practical skills you can immediately apply in your job, ensuring maximum impact.",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Move to the next point every 6 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === points.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000);
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [points.length]);

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === points.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? points.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="w-full max-w-[100%] md:max-w-[100%] lg:max-w-[100%] xl:max-w-[100%] h-auto md:h-[280px]  py-8 md:py-12  bg-[#0C1524] text-white text-center font-poppins">
      <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4 sm:mb-6">
        Why Choose <span className="text-[#FF3131]">M2HSE?</span>
      </h2>

      <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mt-4 mb-2 sm:mb-4">
        Proven Success
      </h3>

      <div className="relative flex items-center justify-center">
        {/* Left Arrow */}
        <button
          onClick={goToPrevious}
          className="absolute left-4 sm:left-6 md:left-8 p-2 text-white hover:text-white">
          <FaChevronLeft size={20} />
        </button>

        {/* Point Display */}
        <p className="text-base sm:text-lg md:text-xl font-medium max-w-sm sm:max-w-md md:max-w-lg mx-auto px-4 sm:px-6">
          {points[currentIndex]}
        </p>

        {/* Right Arrow */}
        <button
          onClick={goToNext}
          className="absolute right-4 sm:right-6 md:right-8 p-2 text-white hover:text-white">
          <FaChevronRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default WhyChooseM2HSECarousel;
