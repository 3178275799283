import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const WhyChooseSMSTS = () => {
  const cards = [
    {
      id: "flexible-options",
      title: "Flexible and Convenient Options",
      points: [
        "Online or Classroom Training: Attend live, interactive sessions remotely or in person at one of our accessible training centers.",
        "Weekend and Weekday Classes: Pick sessions that suit your schedule, minimizing work disruptions.",
      ],
    },
    {
      id: "expert-trainers",
      title: "Expert Trainers with Industry Knowledge",
      points: [
        "CITB-Approved Trainers: Our trainers are experienced professionals with over 10 years in site management.",
        "Practical Insights: Trainers use real-world examples and case studies to make complex topics clear and relatable.",
      ],
    },
    {
      id: "proven-success",
      title: "Proven Success Rates",
      points: [
        "High Pass Rates: A 99% course completion and certification success rate.",
        "Personalized Support: Dedicated guidance during and after the training to ensure your success.",
      ],
    },
    {
      id: "recognized-certification",
      title: "Recognized Certification",
      points: [
        "CITB-Accredited Certificate: Valid for 5 years and recognized across the UK construction industry.",
        "Career Enhancement: Boost your employability and meet the mandatory requirements for site management roles.",
      ],
    },
    {
      id: "interactive-training",
      title: "Interactive and Engaging Training",
      points: [
        "Hands-On Learning: Participate in group discussions, practical exercises, and scenario-based problem-solving.",
        "Real-World Applications: Gain insights on applying course knowledge directly to construction projects.",
      ],
    },
    {
      id: "comprehensive-support",
      title: "Comprehensive Support",
      points: [
        "Digital Course Materials: Access all the necessary resources before, during, and after the course.",
        "Post-Course Guidance: Assistance with certification renewal and staying up-to-date with health and safety regulations.",
      ],
    },
    {
      id: "value-money",
      title: "Value for Money",
      points: [
        "Transparent Pricing: Competitive course fees include all materials, training, and certification—no hidden costs.",
        "Group Discounts: Affordable options for businesses enrolling multiple candidates.",
      ],
    },
    {
      id: "proven-track-record",
      title: "Proven Track Record",
      points: [
        "Trusted by Industry Leaders: Hundreds of construction professionals and companies rely on us for their training needs.",
        "Positive Feedback: Praised for our professionalism, accessibility, and practical course delivery.",
      ],
    },
    {
      id: "additional-benefits",
      title: "Additional Benefits",
      points: [
        "Ongoing Updates: Receive health and safety updates after the course to keep your knowledge current.",
        "Networking Opportunities: Connect with peers in the construction industry during the training.",
      ],
    },
  ];

  return (
    <section className="px-6 py-12 bg-gray-900">
      {/* Header */}
      <div className="max-w-4xl mx-auto text-center space-y-4 mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-bold">
          Why Choose Us for Your SMSTS Training?
        </h2>
        <p className="text-white">
          Our CITB-accredited SMSTS training provides an outstanding learning
          experience tailored for site management professionals.
        </p>
      </div>

      {/* Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white rounded-xl shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              {card.title}
            </h3>
            <ul className="space-y-2 text-gray-700">
              {card.points.map((point, idx) => (
                <li key={idx} className="flex items-start">
                  <span className="text-[#FF3131] mr-2">•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Footer Text */}
      <p className="mt-8 text-center text-[#FF3131] font-semibold">
        Choose us for a smooth, professional, and impactful SMSTS training
        experience that prepares you for success in site management!
      </p>
    </section>
  );
};

export default WhyChooseSMSTS;
