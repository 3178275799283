import React, { useRef, useEffect, useState } from "react";
import achievementLogo from "../../assets/homePage/ribbon.svg";

const WhatsNew = () => {
  const sliderRef = useRef(null);
  const [isAutoScroll, setIsAutoScroll] = useState(true); // State for auto scroll

  const courseData = [
    {
      title: "Level 6 NVQ Diploma in Senior Site Inspection",
      description:
        "The Level 6 NVQ Diploma in Senior Site Inspection validates your expertise in monitoring site quality, managing project progress.",
      price: "£1,160 + VAT",
      detailsPage:
        "/level-6-construction-in-site-management-senior-site-inspection",
    },
    {
      title: "Level 7 NVQ Diploma in Construction Senior Management",
      description:
        "The Level 7 NVQ Diploma in Construction Senior Management validates your ability to manage complex construction projects and teams effectively.",
      price: "£1,400 + VAT",
      detailsPage: "/nvq-level-7-construction-senior-management",
    },
    {
      title:
        "Level 7 Diploma in Strategic Health and Safety Leadership and Management",
      description:
        "The Level 7 Diploma in Strategic Health and Safety Leadership and Management demonstrates your ability to implement and lead health and safety initiatives at the strategic level.",
      price: "£1,400 + VAT",
      detailsPage:
        "/nvq-level-7-Strategic-Health-and-Safety-Leadership-and-Management",
    },
    {
      title: "Level 6 NVQ Diploma in Occupational Health and Safety Practice",
      description:
        "The Level 6 NVQ Diploma in Occupational Health and Safety Practice is designed to showcase your ability to manage health and safety systems, develop policies.",
      price: "£1,160 + VAT",
      detailsPage: "/nvq-level-6-health&safety-practice",
    },
    {
      title: "CITB SMSTS",
      description:
        "This course is designed for construction site managers, project managers, and supervisors to enhance their health and safety skills and competence.",
      price: "£450 + VAT",
      detailsPage: "/smsts-online-course",
    },
    {
      title: "CITB SMSTS Refresher",
      description:
        "This course helps existing managers refresh their health and safety knowledge and maintain compliance with construction site responsibilities.",
      price: "£270 + VAT",
      detailsPage: "/smsts-refresher-course",
    },
    {
      title: "CITB SSSTS",
      description:
        "Designed for individuals working as supervisors in construction to develop their health and safety skills and competence. Certificate valid for 5 years.",
      price: "£240 + VAT",
      detailsPage: "/sssts-online-course",
    },
    {
      title: "CITB SSSTS Refresher",
      description:
        "A refresher course for supervisors to maintain their competence in health and safety supervision. All remotely online, valid for 5 years.",
      price: "£180 + VAT",
      detailsPage: "/sssts-refresher-course",
    },
  ];

  const scrollLeft = () => {
    sliderRef.current.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    sliderRef.current.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };

  const autoScroll = () => {
    if (isAutoScroll) {
      sliderRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(autoScroll, 3000);
    return () => clearInterval(interval);
  }, [isAutoScroll]);

  function truncateText(text, wordLimit) {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ");
    }
    return text;
  }

  return (
    <div className="py-4 sm:px-4 text-white font-poppins">
      {/* Heading */}
      <h2 className="text-2xl md:text-4xl mt-6 font-bold text-black mb-2 md:mb-4 text-center">
        What's <span className="text-[#FF3131]">New?</span>
      </h2>
      <p className="text-base md:text-xl font-normal px-4 md:px-8 text-black lg:px-16 mb-4 text-center">
        We look forward to welcoming you to our training program and supporting
        your journey to a safer and more prosperous future in construction!
      </p>

      {/* Slider */}
      <div className="relative">
        {/* Left Arrow */}
        <button
          className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-[#FF3131] text-white p-2 rounded-full shadow-md hover:bg-red-600 z-10"
          onClick={scrollLeft}>
          &larr;
        </button>

        {/* Slider Content */}
        <div
          ref={sliderRef}
          className="flex gap-4 overflow-x-auto scrollbar-hide p-4 scroll-smooth">
          {courseData.map((course, index) => (
            <div
              key={index}
              onClick={() => (window.location.href = course.detailsPage)}
              className="bg-gray-800 rounded-xl p-4 flex-shrink-0 flex flex-col justify-evenly text-center w-80 h-96 cursor-pointer">
              {/* Icon */}
              <div className="flex justify-center mb-2">
                <img
                  src={achievementLogo}
                  className="w-16 h-16 object-contain"
                  alt="Achievement Logo"
                />
              </div>

              {/* Title */}
              <h3 className="font-semibold text-base sm:text-lg">
                {course.title}
              </h3>

              {/* Description */}
              <p className="text-sm sm:text-sm overflow-hidden">
                {truncateText(course.description, 12)}{" "}
                {course.description.split(" ").length > 12 && (
                  <span className="text-[#FF3131] cursor-pointer ml-2">
                    ...continue reading
                  </span>
                )}
              </p>

              {/* Button */}
              <div className="">
                <button className="rounded-xl bg-[#FF3131] px-4 py-2 text-white text-xs sm:text-sm hover:bg-red-600 transition-all duration-300 focus:outline-none focus:ring">
                  {course.price}
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        <button
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-[#FF3131] text-white p-2 rounded-full shadow-md hover:bg-red-600 z-10"
          onClick={scrollRight}>
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default WhatsNew;
