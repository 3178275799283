import React from "react";
import backgroundImage from "../../assets/Courses/218dc37ca844a835bb8ab8f4e3b625bd.png";
import vector from "../../assets/aboutUsPage/Vector 1.svg";

const AboutUs = () => {
  const cards = [
    {
      title: "Learn from the Best",
      description:
        "Our M2HSE instructors are skilled professionals who really know their stuff. They’re here to teach you the tricks of the trade.",
    },
    {
      title: "Comprehensive Curriculum",
      description:
        "Master essential to advanced construction skills through our hands-on courses designed for real-world readiness.",
    },
    {
      title: "Industry-Relevant Certification",
      description:
        "Earn Recognized Certificates and Personalized Training to Propel Your Construction Career.",
    },
    {
      title: "Advanced Training Facilities",
      description:
        "Learn with the latest tools in a realistic setting, plus get career support after your program ends.",
    },
    {
      title: "Development Support",
      description:
        "Build Your Future with M2HSE: From Skills Training to Job Placement Support in Construction.",
    },
    {
      title: "Flexible Learning Options",
      description:
        "Flexible Construction Training: Online and On-Site Options to Fit Your Life",
    },
  ];

  return (
    <div
      className="relative bg-cover bg-center mt-[100px] py-12 px-4 sm:px-6 lg:px-12"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-slate-100 opacity-95"></div>

      {/* Main Content Wrapper */}
      <div className="relative grid grid-cols-1 lg:grid-cols-1 gap-8 max-w-4xl mx-auto">
        {/* Left Box */}
        <div className="flex flex-col items-center justify-center text-center px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl sm:text-4xl mt-8 font-bold text-[#FF3131] mb-4">
            About Our Company
          </h1>
          <div
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="65e09121938229973209b5ac"
            data-style-height="150px"
            data-style-width="250px">
            <a
              href="https://uk.trustpilot.com/review/m2hse.co.uk"
              target="_blank"
              rel="noopener noreferrer">
              Trustpilot
            </a>
          </div>
          <p className="text-gray-700 text-base sm:text-lg lg:w-[75%]">
            M2HSE is a leading provider of construction training, committing to
            elevating the skills and knowledge of individuals within the sector,
            setting the standard for professionalism and expertise.
          </p>
        </div>

        {/* Right Box */}
        <div className="bg-[#0C1524] text-white p-6 sm:p-8 lg:p-12 rounded-lg">
          <h4 className="text-md font-bold text-center mb-4">Our Values</h4>
          <h1 className="text-center text-3xl sm:text-4xl text-[#FF3131] mb-2">
            Why Choose Us?
          </h1>
          <p className="text-center text-sm sm:text-base mb-6">
            Our training has helped many individuals excel in construction
            careers, earning industry respect and adding significant value to
            their credentials.
          </p>

          {/* Cards */}
          <div className="grid grid-cols-1  sm:grid-cols-2 gap-6">
            {cards.map((card, index) => (
              <div
                key={index}
                className="relative bg-white p-6 rounded-xl shadow-md">
                {/* Icon at Top-Right */}
                <img
                  src={vector}
                  alt="Icon"
                  className="absolute top-0 right-4 w-8 h-8"
                />
                <h5 className="text-lg font-semibold text-[#FF3131] mb-2">
                  {card.title}
                </h5>
                <p className="text-gray-700 text-sm sm:text-base">
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
