import React from "react";

const EntryRequirement = () => {
  const requirements = [
    {
      number: 1,
      text: "You are involved in construction assembly, particularly in the erection of precast concrete flooring.  ",
    },
    {
      number: 2,
      text: "You are familiar with health, safety, and productive working practices in the construction industry.  ",
    },
    {
      number: 3,
      text: "You can provide evidence of your skills and knowledge from your current or past experience.   ",
    },
  ];

  return (
    <div className="bg-gray-100 border border-gray-200 p-6 md:p-10 max-w-7xl mx-auto rounded-2xl shadow-xl">
      {/* Heading */}
      <h2 className="text-xl md:text-2xl font-semibold text-center mb-4">
        Entry Requirement
      </h2>

      {/* Subheading */}
      <p className="text-center text-gray-600 mb-8">
        There are no specific entry requirements for this NVQ; however, it’s
        best suited for individuals who are currently working in construction or
        have relevant experience in installing precast concrete elements. <br />
        This NVQ is designed for individuals who are currently working in
        construction or have experience in the erection of precast concrete
        flooring. You’ll be a suitable candidate if:
      </p>

      {/* Circles Section */}
      <div className="flex flex-col md:flex-row justify-evenly items-center gap-6">
        {requirements.map((item) => (
          <div
            key={item.number}
            className="w-64 h-64 flex flex-col items-center justify-center rounded-full bg-[#0C1524] text-white text-center p-4">
            {/* Number */}
            {/* <span className="w-12 h-12 flex items-center justify-center rounded-full bg-white text-black font-semibold text-lg mb-2">
              {item.number}
            </span> */}
            {/* Text */}
            <p className="text-sm md:text-base leading-relaxed font-thin px-4">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EntryRequirement;
