import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 6 NVQ Diploma in Construction Site Management – Tunnelling
        equips you with the knowledge and skills required to oversee tunnelling
        operations. It validates your expertise in managing complex projects,
        ensuring safe and efficient delivery in compliance with legal and
        environmental standards.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        Assessment Contents & Units
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Developing and maintaining good occupational working
                  relationships in the workplace
                </li>
                <li>
                  Allocating work and monitoring people's performance in the
                  workplace
                </li>
                <li>
                  Contributing to the identification of a work team in the
                  workplace
                </li>
                <li>
                  Establishing, implementing, and maintaining organizational
                  systems for managing health, safety, welfare, and wellbeing in
                  the workplace
                </li>
                <li>
                  Establishing, controlling, and monitoring environmental
                  factors and sustainability in the workplace
                </li>
                <li>Evaluating and confirming work methods in the workplace</li>
                <li>
                  Planning the preparation of the site for the project in the
                  workplace
                </li>
                <li>Monitoring project activities in the workplace</li>
                <li>
                  Ensuring that work activities and resources meet project work
                  requirements in the workplace
                </li>
                <li>
                  Organizing, controlling, and monitoring supplies of materials
                  in the workplace
                </li>
                <li>
                  Identifying and maintaining communication systems and
                  organizational procedures in the workplace
                </li>
                <li>
                  Controlling project progress against agreed quality standards
                  in the workplace
                </li>
                <li>
                  Controlling project progress against agreed programs in the
                  workplace
                </li>
                <li>Managing your personal development in the workplace</li>
                <li>
                  Establishing dimensional control criteria in the workplace
                </li>
                <li>
                  Controlling project quantities and costs in the workplace
                </li>
                <li>Managing the project handover in the workplace</li>
                <li>Planning tunnelling activities in the workplace</li>
                <li>
                  Managing the installation, maintenance, monitoring and removal
                  of temporary works in the workplace
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units: one optional unit is required
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>Enabling learning opportunities in the workplace</li>
                <li>
                  Providing customer services in the construction workplace
                </li>
                <li>Supervising tunnelling activities in the workplace</li>
                <li>
                  Identifying, allocating and planning the deployment and use of
                  plant, equipment or machinery in the workplace
                </li>
                <li>
                  Evaluating feedback and making recommendations in the
                  workplace
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
