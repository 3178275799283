import React from "react";
import CourseCart from "./CartScreen";

const CartScreen = () => {
  return (
    <div>
      <CourseCart />
    </div>
  );
};

export default CartScreen;
