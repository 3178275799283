import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 2 Certificate in Driving Goods Vehicles - Van is tailored for
        individuals working within driving goods vehicles - van. This
        qualification demonstrates your ability to apply your skills within
        driving goods vehicles - van, ensuring compliance with health and safety
        standards and legal requirements.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>Prepare the van for driving</li>
                <li>Protect the van and the load</li>
                <li>Operate and monitor the van systems </li>
                <li>Manoeuvre the van in restricted spaces</li>
                <li>
                  Obtain information on the collection and/or delivery of loads{" "}
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units:
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <h2>Group O1 (Minimum 4 credits)</h2>
                <li>
                  Drive the van on public roads in a safe and fuel efficient
                  manner: 6 credits
                </li>
                <li>
                  Drive the van on private roads in a safe and fuel efficient
                  manner: 4 credits
                </li>

                <h2>Group O2 (Minimum 4 credits)</h2>
                <li>Ensure the van is loaded correctly: 4 credits</li>
                <li>Load the van correctly: 5 credits</li>

                <h2>Group O3 (Minimum 3 credits)</h2>
                <li>Ensure the van is unloaded correctly: 3 credits</li>
                <li>Unload the van correctly: 3 credits</li>

                <h2>Group O4 (Minimum 5 credits)</h2>
                <li>
                  Plan the route and timings for the collection and delivery of
                  goods: 6 credits
                </li>
                <li>
                  Make an effective contribution to a business in the logistics
                  sector: 3 credits
                </li>
                <li>
                  Contribute to the provision of customer service in logistics
                  operations: 3 credits
                </li>
                <li>
                  Dealing with payment transactions in logistics operations: 2
                  credits
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
