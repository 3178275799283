import React, { useEffect, useState } from "react";
import { useCart } from "../../CartContext";
import { ImCross } from "react-icons/im";
import cartImage from "../../assets/cartPage/empty-cart-red.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CourseCart = () => {
  const { removeFromCart, clearCart, getTotalPrice } = useCart();
  const [emiDuration, setEmiDuration] = useState(null);
  const [emiPlans, setEmiPlans] = useState([]);

  // Function to calculate total price including VAT
  const calculateTotalWithVAT = () => {
    const totalPrice = getTotalPrice();
    const vat = totalPrice * 0.2; // 20% VAT
    return totalPrice + vat;
  };

  const cartItems = JSON.parse(localStorage.getItem("cart")) || {};

  // Function to calculate EMI price based on selected duration
  const getEmiPrice = () => {
    const totalWithVAT = calculateTotalWithVAT();
    if (emiDuration?.duration) {
      return (totalWithVAT / emiDuration.duration).toFixed(2);
    }
    return totalWithVAT.toFixed(2);
  };

  const navigate = useNavigate();

  const handleCheckout = () => {
    if (emiDuration?.duration) {
      cartItems.emiPlan = emiDuration;
    }
    navigate("/user-checkout", { state: { cartItems } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/emi/plans");
        setEmiPlans(response.data.plans);
      } catch (error) {
        console.error("Error fetching EMI plans:", error);
      }
    };
    if (cartItems.isEmi) fetchData();
  }, [cartItems.isEmi]);

  const [couponCode, setCouponCode] = useState("");

  const handleCouponChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleApplyCoupon = () => {
    if (couponCode.trim() === "") {
      alert("Please enter a coupon code.");
      return;
    }
  };

  return (
    <div className="bg-[#0C1524] mb-10 sm:mt-36 mt-36 text-white py-12 px-6 sm:px-12 lg:px-20">
      {cartItems?.items.length > 0 ? (
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-12">
          {/* Left Section */}
          <div className="lg:col-span-2 space-y-6">
            {cartItems?.items.map((item) => (
              <div
                key={item._id}
                className="rounded-xl  flex justify-between items-center p-4"
              >
                <div>
                  <h3 className="text-lg text-white">{item.itemName}</h3>
                  <p className="text-gray-400">Price: £{item.price}</p>
                </div>
                <button
                  onClick={() => removeFromCart(item)}
                  className="text-red-600 hover:text-red-800"
                >
                  <ImCross size={16} />
                </button>
              </div>
            ))}

            {/* Coupon Input */}
            <div className="flex justify-between items-center gap-4">
              <input
                type="text"
                placeholder="Discount Code"
                value={couponCode}
                onChange={handleCouponChange}
                className="w-1/2 px-4 py-2 rounded-xl bg-transparent text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              />
              <button
                className="bg-red-600 text-white px-6 py-2 rounded-xl hover:bg-red-700 transition"
                onClick={handleApplyCoupon}
                disabled={couponCode.trim() === ""}
              >
                Apply
              </button>
            </div>

            <hr className="text-gray-800 " />

            {/* EMI Options */}
            <div className="mt-8">
              <h4 className="text-lg text-gray-100 mb-4">
                Pay with a Monthly Fee
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {emiPlans.map((emi) => (
                  <div
                    key={emi.duration}
                    className={`flex flex-col items-center justify-between p-4 cursor-pointer rounded-xl transition ${
                      emiDuration?.duration === emi.duration
                        ? "bg-gray-400 text-white"
                        : "bg-white text-gray-700 hover:bg-gray-700 hover:text-white"
                    }`}
                    onClick={() =>
                      setEmiDuration((prev) =>
                        prev?.duration === emi.duration ? null : emi
                      )
                    }
                  >
                    <p className="text-lg text-[#FF3131] hover:text-white font-semibold">
                      £{(calculateTotalWithVAT() / emi.duration).toFixed(2)}
                    </p>
                    <p className="text-xs mb-2">per month</p>
                    <p className=" text-md font-bold">
                      {" "}
                      <span className="text-xs">for</span> {emi.duration} Months
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="p-6 rounded-xl shadow-md bg-[#1C2B3A]">
            <h3 className="text-xl font-semibold text-white mb-4">
              Order Summary
            </h3>
            <hr className="mb-6 border-gray-600" />

            <div className="space-y-4 text-gray-300">
              <div className="flex justify-between">
                <p>Price:</p>
                <p className="text-white">£{getTotalPrice().toFixed(2)}</p>
              </div>
              <div className="flex justify-between">
                <p>Discount:</p>
                <p className="text-white">£0.00</p>
              </div>
              <div className="flex justify-between">
                <p>VAT (20%):</p>
                <p className="text-white">
                  £{(getTotalPrice() * 0.2).toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between text-white">
                <p className="text-gray-300">Total Amount:</p>
                <p>£{calculateTotalWithVAT().toFixed(2)}</p>
              </div>
              {emiDuration && (
                <div className="flex justify-between text-white">
                  <p className="text-gray-300">
                    You Pay Only <br /> ({emiDuration.duration} for months):
                  </p>
                  <p className="text-[#FF3131] font-semibold">
                    £{getEmiPrice()}
                  </p>
                </div>
              )}
            </div>

            <button
              className="w-full bg-green-600 text-white py-3 rounded-xl mt-10 hover:bg-green-700 transition"
              onClick={handleCheckout}
            >
              Continue to Checkout
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center mt-32">
          <img
            src={cartImage}
            alt="Empty Cart"
            className="mx-auto w-2/3 max-w-sm"
          />
          <p className="text-gray-300 mt-6">Your cart is currently empty.</p>
          <a
            href="/"
            className="mt-8 inline-block bg-red-600 text-white py-3 px-6 rounded-xl hover:bg-red-700 transition"
          >
            Continue Shopping
          </a>
        </div>
      )}
    </div>
  );
};

export default CourseCart;
