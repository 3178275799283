import React from "react";
import backgroundImage from "../../../../../../assets/homePage/m2hse-constructiongirl-backgroundImage.svg";
import icon1 from "../../../../../../assets/nvq/heritagePainter/m2hse-cap.png";
import icon3 from "../../../../../../assets/nvq/heritagePainter/m2hse-card-Icon.png";
import icon2 from "../../../../../../assets/nvq/heritagePainter/m2hse-user-icon.png";

const Hero = () => {
  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Overlay */}
      <div className="absolute inset-0 bg-[#0C1524] opacity-90"></div>

      {/* Content */}
      <div className="relative min-h-[500px] flex flex-col justify-center px-6 sm:px-12 lg:px-20">
        <div className="max-w-6xl mx-auto text-center">
          {/* Main Heading */}
          <h4 className="text-white text-xl mt-24 sm:mt-24 sm:text-2xl">
            Level 2 NVQ Certificate in
          </h4>
          <h1 className="text-[#FF3131] text-3xl sm:text-4xl font-bold mt-1">
            Plant Operations (Construction)
          </h1>
          <h3 className="text-white text-xl sm:text-xl mt-1">
            Transporting Loads
          </h3>

          {/* Enquire Now */}
          <a href="/contact-us">
            <button className="bg-[#FF3131] text-white py-3 px-4 rounded-2xl text-base sm:text-lg font-medium hover:bg-red-600 transition-all mt-1">
              Enquire Now
            </button>
          </a>
        </div>
      </div>

      {/* Cards Section */}
      <div className="relative px-6 sm:px-12 lg:px-20 rounded-t-3xl -mt-16 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          {/* Card 1 */}
          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
            <div className="mb-6">
              <img src={icon1} alt="Icon 1" className="h-12 w-12" />
            </div>
            <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
              Finish Your Qualification in <br />
              <span className="text-[#FF3131] font-semibold">8-10 Weeks</span>
            </p>
          </div>

          {/* Card 2 */}
          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
            <div className="mb-6">
              <img src={icon2} alt="Icon 2" className="h-12 w-12" />
            </div>
            <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
              Apply for a <br />
              <span className="text-[#FF3131] font-semibold">
                Blue CSCS Card
              </span>{" "}
              After Completion
            </p>
          </div>

          {/* Card 3 */}
          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
            <div className="mb-6">
              <img src={icon3} alt="Icon 3" className="h-12 w-12" />
            </div>
            <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
              Ofqual & CITB <br />
              <span className="text-[#FF3131] font-semibold">Approved</span>
              <br />
              <span className="text-[#FF3131] font-semibold">
                Qualification
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
