import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 2 Certificate in Forklift Truck Operations is tailored for
        individuals working within forklift truck operations in construction.
        This qualification demonstrates your ability to apply your skills within
        forklift truck operations, ensuring compliance with health and safety
        standards and legal requirements.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Obtain information on the collection and/or delivery of goods
                </li>
                <li>
                  Make an effective contribution to a business in the logistics
                  sector
                </li>
                <li>
                  Contribute to the provision of customer service in logistics
                  operations
                </li>
                <li>Health, Safety and Security at work</li>
                <li>Operate, move or manoeuvre the fork lift truck</li>
                <li>
                  Select, transfer and position loads using a fork lift truck
                </li>
                <li>Undertake post operational checks for fork lift trucks</li>
                <li>Prepare the fork lift truck for driving or manoeuvring</li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units:
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <h2>Group 1 Optional Units (Minimum 1 credit)</h2>
                <li>
                  Keep work areas clean in logistics operations: 3 credits
                </li>
                <li>
                  Maintain hygiene standards in handling and storing goods in
                  logistics operations: 3 credits
                </li>
                <li>Principles of food safety in logistics: 1 credit</li>

                <h2>Group 2 Optional Units (Minimum 3 credits)</h2>
                <li>
                  Moving and/or handling goods in logistics operations: 4
                  credits
                </li>
                <li>
                  Sort goods and materials for recycling or disposal in
                  logistics operations: 3 credits
                </li>
                <li>Wrap and pack goods in logistics operations: 3 credits</li>

                <h2>Group 3 Optional Units (Minimum 3 credits)</h2>
                <li>
                  Load the container, vehicle, vessel or craft using a fork lift
                  truck: 2 credits
                </li>
                <li>
                  Unload the container, vehicle, vessel or craft using a fork
                  lift truck: 3 credits
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
