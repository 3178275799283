import React from "react";
import backgroundImage from "../../../assets/homePage/m2hse-constructiongirl-backgroundImage.svg";
import icon1 from "../../../assets/nvq/heritagePainter/m2hse-cap.png";
import icon3 from "../../../assets/nvq/heritagePainter/m2hse-card-Icon.png";
import icon2 from "../../../assets/nvq/heritagePainter/m2hse-user-icon.png";

const Hero = () => {
  return (
    <div
      className="relative mt-40 md:mt-24 lg:mt-24 bg-cover bg-center min-h-screen flex flex-col items-center justify-center text-center px-4 sm:px-6 md:px-12"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-white opacity-70"></div>

      {/* Content */}
      <div className="relative z-10 max-w-3xl mx-auto text-black">
        <h1 className="text-xl sm:text-2xl md:text-4xl font-bold mb-2 leading-tight">
          Level 1 Award in<br />
          <span className="text-red-500">
            Health and Safety in a Construction Environment
          </span>
        </h1>

        {/* TrustBox Widget */}
        <div className="flex justify-center mt-4">
          <div
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="65e09121938229973209b5ac"
            data-style-height="150px"
            data-style-width="250px"
          >
            <a
              href="https://uk.trustpilot.com/review/m2hse.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </div>

        {/* Enquire Button */}
        <a href="/contact-us">
          <button className="bg-[#FF3131] hover:bg-red-600 text-white font-semibold py-3 px-8 rounded-xl transition-all mt-2">
            Enquire Now
          </button>
        </a>

        {/* Cards Section */}
        <div className="mt-16 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-xl">
            <div className="mb-6">
              <img src={icon1} alt="Icon 1" className="h-12 w-12" />
            </div>
            <p className="text-center text-sm md:text-base">
              Complete Your Qualification in <br />
              <span className="text-[#FF3131] font-semibold">1 Day</span>
            </p>
          </div>

          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-xl">
            <div className="mb-6">
              <img src={icon3} alt="Icon 2" className="h-12 w-12" />
            </div>
            <p className="text-center text-sm md:text-base">
              Apply for a <br />
              <span className="text-[#FF3131] font-semibold">Green CSCS Card</span> <br />
              After Completion
            </p>
          </div>

          <div className="bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-xl">
            <div className="mb-6">
              <img src={icon2} alt="Icon 3" className="h-12 w-12" />
            </div>
            <p className="text-center text-sm md:text-base">
              Ofqual & CITB
              <br />
              <span className="text-[#FF3131] font-semibold">
                Approved <br /> Qualification
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
