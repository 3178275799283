import React from "react";
import Hero from "./hero";
import IndustryRecognitionBenefits from "./IndustryRecognition";
import AssessmentBox from "./AboutThisNvqAssessment";
import KeyFeaturesBox from "./KeyFeaturesBox";
import WhyChooseM2HSECarousel from "./WhyM2hse";
import FAQ from "./Faqs";
import RelatedCourses from "./RelatedCourses";
import CoursePricing from "./CoursePricing";
import EntryRequirement from "./EntryRequirements";
import HowItWorks from "./HowItsWork";
import FaqAndContact from "../../Contact_Us/GetInTouch/FAQsandContactForm";
import Certification from "./Certification";
import CourseOverview from "./CourseOverview";
import CourseSteps from "./CourseSteps";
import WhyChooseUs from "./HowItsWork";
import HowToApply from "./HowToApply";
import { Helmet } from "react-helmet";

const AwardLevel1 = () => {
  return (

    <>
    <Helmet>
    <link rel="icon" href="/favicon.ico" />
  </Helmet>
    <div>
      <Hero />
      <CourseOverview/>
      <CourseSteps/>
      <WhyChooseUs />
      <KeyFeaturesBox />
      <HowToApply/>
      {/* <CoursePricing /> */}
      {/* <EntryRequirement /> */}
      <FAQ />
     
      {/* <AssessmentBox /> */}
      {/* <Certification /> */}
      {/* <IndustryRecognitionBenefits /> */}
      
      <WhyChooseM2HSECarousel />
      <FaqAndContact />
      <RelatedCourses />
    </div>
    </>
  );
};

export default AwardLevel1;
