import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCart } from "../../../../CartContext";

const LevelOneGreenCard = () => {
  const { addToCart } = useCart();
  const [activeFilter, setActiveFilter] = useState("dayRelease");
  const [activeMonth, setActiveMonth] = useState(0);
  const [data, setData] = useState([]);
  
  const toggleMonth = (month) => {
    setActiveMonth(activeMonth === month ? null : month);
  };

  function formatDateRange(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];

    const startDay = start.getDate().toString().padStart(2, "0");
    const startMonth = monthNames[start.getMonth()];

    const timeDifference = end - start;
    const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

    const dateRange = `${startDay}-${startMonth}`;

    return { dateRange, days: `${numberOfDays} Days` };
  }

  useEffect(() => {
    axios
      .get(`slots/filter`, {
        params: {
          filter: "scheduleDays",
          equal: activeFilter,
          productId: "66028fd2b9e94f407d047362",
        },
      })
      .then((result) => {
        const currentDate = new Date();
        const filteredData = result?.data?.result?.map((slots) => {
          return {
            ...slots,
            slots: slots?.slots?.filter((slot) => {
              const slotStartDate = new Date(slot.startDate);
              return slotStartDate >= currentDate; 
            }),
          };
        });

        setData(filteredData);
      })
      .catch((err) => console.log(err));
  }, [activeFilter]);

  const formatYearMonth = (year, month) => {
    const date = new Date(year, month - 1);
    const monthName = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",
    ];

    return `${monthName[date.getMonth()]} ${year}`;
  };

  const handleAddToCart = (product) => {
    product.isEmi = false;
    addToCart(product);
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center py-10">
      <div className="w-11/12  mt-[100px] md:w-9/12 lg:w-8/12 xl:w-7/12">
        <div className=" p-6 ">
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-center font-bold text-gray-900">
            Level 1 <br /><span className="text-[#FF3131]" >Award in Health and Safety</span>  <br /> in a Construction Environment
          </h1>
          {/* TrustBox Widget */}
          <div className="flex justify-center mt-4">
            <div
              className="trustpilot-widget"
              data-locale="en-GB"
              data-template-id="53aa8807dec7e10d38f59f32"
              data-businessunit-id="65e09121938229973209b5ac"
              data-style-height="150px"
              data-style-width="250px"
            >
              <a href="https://uk.trustpilot.com/review/m2hse.co.uk" target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>
          </div>
          <div className="flex flex-col justify-center sm:flex-row gap-4 mt-4">
            <button className={`w-full sm:w-auto px-4 py-2 rounded font-semibold transition ${"bg-[#FF3131] text-white shadow-lg"}`}>
              Available Slots
            </button>
          </div>
        </div>

        <div className="mt-4">
          {data?.map((slots, index) => (
            <div key={index} className="mb-4">
              <div className="flex justify-between items-center bg-white p-4 border-b-2 cursor-pointer" onClick={() => toggleMonth(index)}>
                <h2 className="text-[#FF3131] font-bold">
                  {formatYearMonth(slots["_id"].year, slots["_id"].month)}
                </h2>
                <span className="text-black">{activeMonth === index ? "-" : "+"}</span>
              </div>

              {activeMonth === index && (
                <div className="bg-white p-4">
                  {/* Course Boxes */}
                  {slots?.slots?.map((slot, index) => (
                    <div
                      onClick={() => {
                        window.location.href = `/green-cscs-card-course-detail/${slot._id}`;
                      }}
                      key={index}
                      className="flex flex-col md:flex-row justify-between items-center bg-white hover:bg-gray-100 shadow-md rounded-b-xl border-l-4 border-[#FF3131] my-4 p-4 hover:shadow-md hover:shadow-[#FF3131] transition duration-300"
                    >
                      <div className="flex items-center w-full md:w-2/3 space-x-4">
                        <div className="w-12 h-12 bg-[#FF3131] text-white flex items-center justify-center rounded-full font-bold">
                          {index + 1}
                        </div>

                        {/* Course Details */}
                        <div className="text-left">
                          <p className="text-gray-800 font-semibold text-lg">
                            Level 1 Award <br />
                            <span className="text-blue-500 text-xs">(Tutor-Led Online Classroom)</span>
                          </p>
                          <div className="flex flex-col mt-1 text-sm text-gray-600">
                            <span>📅 {formatDateRange(slot.startDate, slot.endDate).dateRange}</span>
                            <span className="mt-1">⏳ {slot?.scheduleDates?.length} day</span>
                          </div>
                          <p className="text-[#FF3131] font-bold text-md mt-2">
                            £{slot?.productDetails?.price} + VAT
                          </p>
                        </div>
                      </div>

                      {/* Right Section: Buttons */}
                      <div className="flex gap-2 w-full mt-4 md:mt-0 justify-center md:justify-end">
                        <button
                          className="bg-[#FF3131] text-white px-4 py-2 rounded hover:bg-red-600 transition"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddToCart(slot);
                          }}
                        >
                          Add to Cart
                        </button>
                        <a href={`/green-cscs-card-course-detail/${slot._id}`}>
                          <button className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 transition">More Info</button>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LevelOneGreenCard;
