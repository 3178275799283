import React, { useState, useCallback, useEffect, useMemo } from "react";
import countries from "./countries";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutForm from "./checkout";

const CustomerDetails = () => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (typeof state?.cartItems !== "object") {
      window.location.href = "/cart-checkout";
      return;
    }
  }, []);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    phone: "",
    email: "",
    companyName: "",
    message: "Create through website...",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "firstname",
      "lastname",
      "address",
      "country",
      "state",
      "city",
      "postalCode",
      "phone",
      "email",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field} is required`;
      }
    });

    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const navigate = useNavigate();
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post("/client/create-client", formData);
        localStorage.setItem("client", JSON.stringify(response.data.result));
        setShowPaymentMethods(true);
        navigate("/stripe-checkout", {
          state: { status: showPaymentMethods, cartItems: state?.cartItems },
        });
      } catch (error) {
        console.error("Error creating client:", error);
      }
    }
  };

  return (
    <div className="bg-[#0C1524] flex items-center justify-center px-4 p-6 sm:px-6 lg:px-8">
      <div
        className="bg-white mt-36 rounded-xl shadow-lg p-6 sm:p-10 max-w-2xl w-full relative overflow-hidden"
        style={{ maxHeight: "100vh", overflowY: "auto" }}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl sm:text-2xl font-semibold text-[#0C1524]">
            Customer Details
          </h2>
        </div>
        <form className="space-y-4">
          {/* First and Last Name */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="firstname"
                className="block text-sm font-medium text-gray-700"
              >
                First Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
              />
              {errors.firstname && (
                <p className="text-red-500 text-sm">{errors.firstname}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
              />
              {errors.lastname && (
                <p className="text-red-500 text-sm">{errors.lastname}</p>
              )}
            </div>
          </div>

          {/* Address */}
          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Address<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="address"
              value={formData.address}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
            />
            {errors.address && (
              <p className="text-red-500 text-sm">{errors.address}</p>
            )}
          </div>

          {/* Country and State */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country<span className="text-red-500">*</span>
              </label>
              <select
                id="country"
                value={formData.country}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
              >
                <option value="">Select Country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              {errors.country && (
                <p className="text-red-500 text-sm">{errors.country}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="state"
                value={formData.state}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
              />
              {errors.state && (
                <p className="text-red-500 text-sm">{errors.state}</p>
              )}
            </div>
          </div>

          {/* City and Postal Code */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="city"
                value={formData.city}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
              />
              {errors.city && (
                <p className="text-red-500 text-sm">{errors.city}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                Postal Code<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
              />
              {errors.postalCode && (
                <p className="text-red-500 text-sm">{errors.postalCode}</p>
              )}
            </div>
          </div>

          {/* Contact Number */}
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Contact Number<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
            />
            {errors.phone && (
              <p className="text-red-500 text-sm">{errors.phone}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email<span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
          </div>

          {/* Company */}
          <div>
            <label
              htmlFor="companyName"
              className="block text-sm font-medium text-gray-700"
            >
              Company
            </label>
            <input
              type="text"
              id="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-md focus:ring-[#0C1524] focus:border-[#0C1524]"
            />
          </div>

          {/* Submit Button */}
          <div className="pt-9">
            <button
              type="button"
              onClick={handleSubmit}
              className="w-full bg-[#22C55E] text-white py-3 rounded-2xl hover:bg-[#16a34a] transition"
            >
              Proceed to Pay
            </button>
          </div>
        </form>
        {/* Payment Methods Section */}
        <div
          className={`absolute top-0 left-0 w-full bg-white p-6 sm:p-10 transform z-40 transition-transform duration-300 ${
            showPaymentMethods ? "translate-x-0" : "translate-x-full"
          }`}
          style={{ maxHeight: "100vh", overflowY: "auto" }}
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl sm:text-2xl font-semibold text-[#0C1524]">
              Payment Methods
            </h2>
            <button
              onClick={() => setShowPaymentMethods(false)}
              className="text-sm text-[#0C1524] underline"
            >
              Back
            </button>
          </div>
          <div className="space-y-4">
            <CheckoutForm
              status={showPaymentMethods}
              cartItems={state?.cartItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
