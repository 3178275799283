import React from "react";
import CardsScreen from "../NVQ_Courses/CardsScreen";

const NvqProducts = () => {
  return (
    <div>
      <CardsScreen />
    </div>
  );
};

export default NvqProducts;
