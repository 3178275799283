import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const WhyChooseCitb = () => {
  const cards = [
    {
      id: "flexible-options",
      title: "Flexible and Convenient Options",
      points: [
        "Online or In-Person Training: Choose between virtual sessions or classroom-based training to suit your preferences.",
        "Weekend and Weekday Options: Select a schedule that works best for you without disrupting your commitments.",
      ],
    },
    {
      id: "expert-trainers",
      title: "Expert Trainers with Proven Expertise",
      points: [
        "CITB-Approved Professionals: Our trainers are highly experienced in site management and health and safety.",
        "Practical Insights: Trainers use real-world case studies and scenarios to clarify and reinforce key topics.",
      ],
    },
    {
      id: "proven-success",
      title: "High Success Rates",
      points: [
        "99% Certification Rate: Most participants successfully complete the course and renew their SMSTS certification.",
        "Comprehensive Support: Receive personalized guidance throughout the course to ensure your success.",
      ],
    },
    {
      id: "recognized-certification",
      title: "Recognized Certification",
      points: [
        "CITB-Accredited Renewal Certificate: Valid for 5 years, ensuring continued compliance and employability.",
        "Enhanced Career Prospects: Demonstrates your commitment to maintaining high standards in site management.",
      ],
    },
    {
      id: "interactive-training",
      title: "Interactive and Engaging Training",
      points: [
        "Group Discussions and Practical Exercises: Engage in interactive learning to reinforce key concepts.",
        "Real-World Applications: Apply the knowledge directly to your ongoing site management responsibilities.",
      ],
    },
    {
      id: "value-for-money",
      title: "Value for Money",
      points: [
        "Transparent Fees: Course costs include all materials, training, and certification, with no hidden charges.",
        "Group Discounts: Cost-effective solutions for companies enrolling multiple site managers.",
      ],
    },
  ];

  return (
    <section className="px-6 py-12 bg-gray-900">
      {/* Header */}
      <div className="max-w-4xl mx-auto text-center space-y-4 mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-bold">
          Why Choose Us for Your SMSTS Refresher Training?
        </h2>
        <p className="text-white">
          Our CITB-accredited SMSTS Refresher course is designed to provide an
          efficient, focused, and results-driven learning experience.
        </p>
      </div>

      {/* Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white rounded-xl shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              {card.title}
            </h3>
            <ul className="space-y-2 text-gray-700">
              {card.points.map((point, idx) => (
                <li key={idx} className="flex items-start">
                  <span className="text-green-500 mr-2">•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Footer Text */}
      <p className="mt-8 text-center text-[#FF3131] font-semibold">
        Choose us for a streamlined, high-quality SMSTS Refresher training
        experience that helps you maintain your certification with ease!
      </p>
    </section>
  );
};

export default WhyChooseCitb;
