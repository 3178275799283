import React from "react";
import backgroundImage from "../../../../assets/homePage/m2hse-constructiongirl-backgroundImage.svg";
import icon1 from "../../../../assets/nvq/heritagePainter/m2hse-cap.png";
import icon3 from "../../../../assets/nvq/heritagePainter/m2hse-card-Icon.png";
import icon2 from "../../../../assets/nvq/heritagePainter/m2hse-user-icon.png";

const Hero = () => {
  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}>
      {/* Overlay */}
      <div className="absolute inset-0 bg-[#0C1524] bg-opacity-90"></div>

      {/* Content */}
      <div className="relative min-h-[500px] flex flex-col justify-center px-6 sm:px-12 lg:px-20">
        <div className="max-w-7xl mx-auto sm:mt-36 mt-36 text-center">

          

          {/* Main Heading */}
          <h4 className="text-white text-lg sm:text-xl md:text-2xl">
            Level 7 NVQ Diploma in
         </h4>
          <h1 className="text-[#FF3131] text-3xl sm:text-4xl md:text-5xl font-bold mt-2">
            Strategic Health and Safety
          </h1>
          <h1 className="text-[#FF3131] text-3xl sm:text-4xl md:text-5xl font-bold mt-2">
            Leadership and Management
          </h1>
          <div class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="65e09121938229973209b5ac" data-style-height="150px" data-style-width="100%" data-theme="dark">
          <a href="https://uk.trustpilot.com/review/m2hse.co.uk" target="_blank" rel="noopener">Trustpilot</a>
            </div>
          {/* Enquire Now */}
          <a href="/contact-us">
            <button className="bg-[#FF3131] text-white py-3 px-8 rounded-lg text-base sm:text-lg font-medium hover:bg-[#ff4d4d] transition-all ">
              Enquire Now
            </button>
          </a>
        </div>
      </div>

      {/* Cards Section */}
      <div className="relative w-full px-6 sm:px-12 lg:px-20 flex flex-wrap sm:flex-row justify-center items-center space-y-6 sm:space-y-0 sm:space-x-8 md:space-x-12 lg:space-x-16 mt-8 sm:mt-12 md:mt-16">
        {/* Card 1 */}
        <div className="bg-[#101828] text-white shadow-lg py-6 px-4 flex flex-col rounded-3xl items-center justify-center w-full sm:w-36 md:w-48 lg:w-56">
          <div className="text-[#FF3131] p-3 rounded-full mb-4 sm:mb-0 sm:mr-4">
            <img src={icon1} alt="Icon 1" />
          </div>
          <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
            Finish Your Qualification in <br />
            <span className="text-[#FF3131] font-semibold">6-8 Weeks</span>
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-[#101828] text-white shadow-lg py-6 px-4 flex flex-col  rounded-3xl items-center justify-center w-full sm:w-36 md:w-48 lg:w-56">
          <div className="text-[#FF3131] p-3 rounded-full mb-4 sm:mb-0 sm:mr-4">
            <img src={icon2} alt="Icon 2" />
          </div>
          <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
            Apply for a <br />
            <span className="text-[#FF3131] font-semibold">
              Black CSCS Card
            </span>{" "}
            After Completion
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-[#101828] text-white shadow-lg py-6 px-4 flex flex-col rounded-3xl items-center justify-center w-full sm:w-36 md:w-48 lg:w-56">
          <div className="text-[#FF3131] p-3 rounded-full mb-4 sm:mb-0 sm:mr-4">
            <img src={icon3} alt="Icon 3" />
          </div>
          <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
            Ofqual & CITB <br />
            <span className="text-[#FF3131] font-semibold">Approved</span>
            <br />
            <span className="text-[#FF3131] font-semibold">Qualification</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
