import React from 'react';

const HowToApply = () => {
  const steps = [
    {
      step: '1.',
      title: 'Pass the CITB Health, Safety & Environment Test',
      description: [
        'Book the test through the CITB website.',
        'Choose the Operatives Test, which costs £22.50.',
        'Prepare for the test using revision materials like the CITB app or book.'
      ]
    },
    {
      step: '2.',
      title: 'Apply for Your Green Card',
      description: [
        'Once you have your Level 1 Award certificate and have passed the CITB test, call the CSCS helpline or apply online at the CSCS website.',
        'Pay the £36 application fee.'
      ]
    },
    {
      step: '3.',
      title: 'Receive Your CSCS Green Labourer Card',
      description: [
        'Your card will be sent to your registered address, typically within 7–10 working days.'
      ]
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-6 py-12">
      <h2 className="text-4xl font-bold text-center mb-6 text-black">How to Apply for a <span className='text-[#FF3131]' >CSCS Green Labourer Card</span> </h2>
      <p className="text-lg text-center text-gray-700 mb-12">Follow these steps to obtain your CSCS Green Card after completing the course:</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {steps.map((step, index) => (
          <div key={index} className="relative bg-gradient-to-r from-gray-100 to-gray-300 p-8 rounded-xl shadow-lg">
            <div className="absolute top-0 left-0 bg-red-600 text-white text-lg font-bold rounded-full w-14 h-14 flex items-center justify-center transform -translate-x-6 -translate-y-6 shadow-md">
              {step.step}
            </div>
            <h3 className="text-2xl font-bold mb-4 text-gray-900">{step.title}</h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              {step.description.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowToApply;
