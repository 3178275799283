import React from "react";

const Certification = () => {
  return (
    <section className="p-6  text-gray-800">
      <div className=" mx-auto">
        <h2 className="text-3xl font-bold text-center text-[#FF3131]">
          Certifications{" "}
        </h2>
        <div className="grid gap-6 my-8 lg:grid-cols-3">
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              1
            </div>
            <p className="text-md">
              <b>Recognized Qualification:</b>Achieve the NVQ Level 2
              Certificate in Fitted Interiors, accredited by OfQual and CITB.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              2
            </div>
            <p className="text-md">
              <b> CSCS Card Eligibility:</b>Qualify to apply for a CSCS Card,
              verifying your skillset in construction.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              3
            </div>
            <p className="text-md">
              <b>Career Growth:</b>This qualification adds to your credentials,
              making you a valuable asset in interior fitting within the
              construction industry.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              4
            </div>
            <p className="text-md">
              This OfQual and CITB-accredited certification formally establishes
              your expertise, validating your skills in fitted interiors.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;
