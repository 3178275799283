import { useCallback, useEffect, useMemo } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { STRIPE_KEY } from "../../config/serverApiConfig";

const stripePromise = loadStripe(STRIPE_KEY);

const CheckoutForm = ({ status, cartItems }) => {
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (typeof state?.cartItems !== "object") {
      window.location.href = "/cart-checkout";
      return;
    }
  }, []);

  const memoizedCartItems = useMemo(() => state?.cartItems, [state?.cartItems]);
  const fetchClientSecret = useCallback(() => {
    const client = JSON.parse(localStorage.getItem("client")) || {};
    const products = {
      items: memoizedCartItems.items,
      emiPlans: memoizedCartItems.emiPlan,
      client: client._id,
      cart: memoizedCartItems._id,
    };

    return axios
      .post("/checkout/create-checkout-payment", { products })
      .then((response) => {
        return response.data.clientSecret;
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="App mt-36">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
