import React from "react";

const RefundPolicy = () => {
  const sections = [
    {
      title: "01. General Refund Terms",
      content: (
        <>
          <p>
            Refund requests will be considered under the following conditions:
          </p>
          <ul className="list-disc list-inside">
            <li>
              The learner must submit the refund request in writing via email at{" "}
              <a
                href="mailto:sales@m2hse.co.uk"
                className="text-blue-500 underline">
                sales@m2hse.co.uk
              </a>
            </li>
            <li>
              All refund requests must be received within{" "}
              <strong>14 days</strong> of the original payment date.
            </li>
            <li>
              Refunds will be processed within <strong>14 business days</strong>{" "}
              from the date the refund is approved.
            </li>
            <li>
              Any refund issued will be made via the original payment method.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "02. NVQs (National Vocational Qualifications)",
      content: (
        <>
          <h4 className="font-semibold">
            2.1. Cancellation Before Course Start Date
          </h4>
          <p>
            If a learner cancels their enrollment{" "}
            <strong>before registration</strong> is completed, they are entitled
            to a full refund minus a{" "}
            <strong>non-refundable administrative fee of £190</strong>.
          </p>
          <h4 className="font-semibold mt-4">
            2.2. Cancellation After Registration
          </h4>
          <p>
            Once the learner has been registered with the awarding body,{" "}
            <strong>no refunds</strong> will be issued.
          </p>
          <h4 className="font-semibold mt-4">2.3. Exceptional Circumstances</h4>
          <p>
            In cases of exceptional circumstances, such as illness or
            bereavement, a partial refund or deferral may be considered.
            Supporting documentation is required.
          </p>
          <h4 className="font-semibold mt-4">
            2.4. Failure to Complete the Course{" "}
          </h4>
          <p>
            If the learner fails to complete the course by the expected end date
            due to lack of attendance, non-completion of assignments, or other
            personal issues, no refund will be granted.
          </p>
        </>
      ),
    },
    {
      title: "03. CITB SSP (Site Safety Plus) Courses",
      content: (
        <>
          <p>
            The CITB SSP courses include health, safety, and other related
            training for the construction industry. Refund policies for these
            courses are as follows:
          </p>

          <h4 className="font-semibold mt-4">
            3.1. Cancellation Before Course Start Date
          </h4>
          <p>
            If a learner cancels their booking for a CITB SSP course at least{" "}
            <strong>7 days</strong> before the course start date, they are
            entitled to a refund minus a{" "}
            <strong>£190 administrative fee</strong>.
          </p>

          <h4 className="font-semibold mt-4">
            3.2. Cancellation Within 7 Days of the Course Start Date
          </h4>
          <p>
            <strong>No refunds</strong> will be issued if the cancellation
            occurs <strong>within 7 days</strong> of the course start date.
          </p>

          <h4 className="font-semibold mt-4">3.3. Non-Attendance</h4>
          <p>
            If the learner does not attend the CITB SSP course,{" "}
            <strong>no refund</strong> will be issued, and{" "}
            <strong>no notice</strong> will be accepted.
          </p>

          <h4 className="font-semibold mt-4">3.4. Transfer to Another Date</h4>
          <p>
            Learners can transfer their booking to another date by providing at
            least <strong>72 hours' notice</strong> before the course start
            date. Transfers are subject to availability, and any price
            difference between the original and new course date or an admin fee
            must be paid.
          </p>
        </>
      ),
    },
    {
      title: "04. Level-1 Award Courses",
      content: (
        <>
          <h4 className="font-semibold">
            4.1. Cancellation Before Course Start Date
          </h4>
          <p>
            If a learner cancels their enrollment 72 hours before the course
            starts, a full refund will be granted minus an administrative fee of
            £70. No refunds applicable on a cancelation within 72 hours of the
            course start date.
          </p>
          <h4 className="font-semibold mt-4">
            4.2. Cancellation After Course Start Date
          </h4>
          <p>
            If a learner cancels or withdraws from the Level-1 Award course
            after the start date, no refund will be provided.
          </p>
          <h4 className="font-semibold mt-4">
            4.3. Exceptional Circumstances for Level-1 Award
          </h4>
          <p>
            Refunds or deferrals may be considered in cases of exceptional
            circumstances such as:
            <ul>
              <li>Medical issues: A medical certificate must be provided.</li>
              <li>
                Other extenuating circumstances: Documentation will be required.
              </li>
            </ul>
            If the learner’s exceptional circumstances are accepted, they may be
            eligible for:
            <ul>
              <li>
                A partial refund based on the time and resources already used,
                or
              </li>
              <li>A deferral to a later date without additional charges.</li>
            </ul>
          </p>
          <h4 className="font-semibold mt-4">4.4. No Show/Non-Completion</h4>
          <p>
            If a learner fails to attend or complete the course without
            notifying the provider, no refund will be given. The learner may
            need to pay again for future attempts.
          </p>
        </>
      ),
    },
    {
      title: "05. Course Rescheduling by Provider",
      content: (
        <>
          <p>
            If we need to cancel or reschedule a course, learners will be
            offered:
            <ul>
              <li>A full refund, or</li>
              <li>
                The opportunity to transfer to another available date at no
                extra charge.
              </li>
            </ul>
            We will notify affected learners as soon as possible and provide
            alternative options.
          </p>
        </>
      ),
    },
    {
      title: "06. Special Conditions for Corporate or Group Bookings",
      content: (
        <>
          <p>
            For corporate or group bookings, the following applies:
            <ul>
              <li>
                Cancellation prior to 14 days: Full refund minus an
                administrative fee of 30% of the order value.
              </li>
              <li>
                Cancellation within 7-14 days: 50% refund of the total amount
                paid.
              </li>
              <li>Cancellation within 7 days: No refund will be issued.</li>
              <li>
                Substitutions may be made at least 72 hours prior to the course
                start date without penalty.
              </li>
            </ul>
            These terms are not applicable to CITB SSP courses.
          </p>
        </>
      ),
    },
    {
      title: "07. How to Request a Refund",
      content: (
        <>
          <p>To request a refund, please follow these steps:</p>
          <ol>
            <li>1. Email us at sales@m2hse.co.uk.</li>
            <li>
              2. Provide your full name, booking details (course name, date),
              and reason for the refund request.
            </li>
            <li>
              3. Include any relevant supporting documents, such as medical
              certificates or evidence of exceptional circumstances.
            </li>
          </ol>
        </>
      ),
    },
    {
      title: "08. Refund Processing",
      content: (
        <>
          <p>
            Once a refund request is received and reviewed, you will be notified
            of the outcome within 14 business days. Refunds will be processed to
            the original payment method. Processing times may vary depending on
            your payment provider.
          </p>
        </>
      ),
    },
    {
      title: "09. Refunds for Payment Errors",
      content: (
        <>
          <p>
            If you believe you have been charged incorrectly (e.g., duplicate
            payments, incorrect course fees), please contact us immediately. We
            will investigate and resolve the issue, issuing a refund if
            applicable.
          </p>
        </>
      ),
    },
    {
      title: "10. Changes to This Refund Policy",
      content: (
        <>
          <p>
            We reserve the right to modify this Refund Policy at any time. Any
            changes will be posted on our website, and the revised policy will
            apply to all future course enrollments. It is your responsibility to
            review the Refund Policy regularly.
          </p>
          <p>
            By enrolling in any of our courses, you acknowledge that you have
            read and agree to the terms outlined in this Refund Policy.
          </p>
          <p>
            If you have any further questions, please contact our support team
            at sales@m2hse.co.uk.
          </p>
        </>
      ),
    },
  ];

  return (
    <div className="bg-[#0C1524] py-10 px-4 mt-32 sm:px-8 lg:px-16">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-xl p-6 sm:p-10">
        <h1 className="text-3xl font-bold text-center mb-8">Refund Policy</h1>
        <p className="text-gray-600 mb-6">
          We are committed to ensuring that all our learners have a positive
          experience with our NVQ, CITB SSP courses, and Level 1 Award programs.
          However, we understand that unforeseen circumstances may arise,
          requiring the need to process refunds. This Refund Policy outlines the
          terms and conditions under which refunds will be considered.
        </p>

        <div className="space-y-6">
          {sections.map((section, index) => (
            <details
              key={index}
              className="group bg-gray-50 border border-gray-200 rounded-lg p-4">
              <summary className="text-lg font-medium cursor-pointer text-gray-700">
                {section.title}
              </summary>
              <div className="mt-2 text-gray-600">{section.content}</div>
            </details>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
