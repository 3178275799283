import React from "react";

const WhyChooseCitb = () => {
  const cards = [
    {
      title: "Recognized Certification",
      points: [
        "Gain a CITB-accredited certificate that is valid for 5 years, recognized across the UK construction industry.",
        "Enhance your employability and meet mandatory requirements for supervisory roles.",
      ],
    },
    {
      title: "Interactive and Engaging Training",
      points: [
        "Hands-On Learning: Participate in group discussions, syndicate exercises, and practical activities.",
        "Real-World Applications: Learn how to apply knowledge directly to real-life construction scenarios.",
      ],
    },
    {
      title: "Comprehensive Support",
      points: [
        "Receive all course materials digitally, making it easy to follow along during training and review after completion.",
        "Our team provides pre-course and post-course support, including guidance on certification renewal when the time comes.",
      ],
    },
    {
      title: "Value for Money",
      points: [
        "Competitive pricing with no hidden costs – the fee includes training, materials, and certification.",
        "Group discounts available for organizations enrolling multiple supervisors.",
      ],
    },
    {
      title: "Proven Track Record",
      points: [
        "Trusted by hundreds of construction companies and individuals across the UK.",
        "Excellent feedback from past participants who have praised our professionalism, accessibility, and comprehensive course delivery.",
      ],
    },
    {
      title: "Flexible and Convenient Options",
      points: [
        "Online Interactive Classes: Attend live training sessions from the comfort of your home or office.",
        "Weekend and Weekday Sessions: Choose dates that fit your schedule, minimizing disruption to your work.",
      ],
    },
    {
      title: "Expert Trainers with Industry Experience",
      points: [
        "Our trainers are CITB-approved professionals with over 10 years of hands-on experience in construction and site supervision.",
        "They use real-life examples and case studies to make complex topics easy to understand.",
      ],
    },
    {
      title: "Proven Success Rates",
      points: [
        "A 99% course completion and certification rate, ensuring that you’ll leave with the skills and credentials you need.",
        "Tailored support throughout the training to help you achieve success.",
      ],
    },
  ];

  return (
    <section className="px-6 py-12 bg-gray-900">
      {/* Header */}
      <div className="max-w-4xl mx-auto text-center space-y-4 mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-bold">
          Why Choose Us for Your SSSTS Training?
        </h2>
        <p className="text-white">
          Our CITB-accredited SSSTS training provides an outstanding learning
          experience tailored for site supervisors.
        </p>
      </div>

      {/* Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white rounded-xl shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              {card.title}
            </h3>
            <ul className="space-y-2 text-gray-700">
              {card.points.map((point, idx) => (
                <li key={idx} className="flex items-start">
                  <span className="text-[#FF3131] mr-2">•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Footer Text */}
      <p className="mt-8 text-center text-[#FF3131] font-semibold">
        Choose us for a seamless, high-quality SSSTS training experience that
        sets you apart in the construction industry!
      </p>
    </section>
  );
};

export default WhyChooseCitb;
