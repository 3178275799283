import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const WhyChooseCitb = () => {
  const cards = [
    {
      id: "flexible-options",
      title: "Flexible and Convenient Options",
      points: [
        "Online Interactive Classes: Attend live training sessions from the comfort of your home or office.",
        "Weekend and Weekday Sessions: Choose dates that fit your schedule.",
      ],
    },
    {
      id: "expert-trainers",
      title: "Expert Trainers with Industry Experience",
      points: [
        "Our trainers are CITB-approved professionals with over 10 years of hands-on experience.",
        "Real-life examples and case studies simplify complex topics.",
      ],
    },
    {
      id: "proven-success",
      title: "Proven Success Rates",
      points: [
        "A 99% course completion and certification rate.",
        "Tailored support to ensure your success.",
      ],
    },
    {
      id: "recognized-certification",
      title: "Recognized Certification",
      points: [
        "CITB-accredited certificate valid for 5 years.",
        "Recognized across the UK construction industry.",
      ],
    },
    {
      id: "interactive-training",
      title: "Interactive and Engaging Training",
      points: [
        "Hands-On Learning: Participate in group discussions.",
        "Real-World Applications for practical learning.",
      ],
    },
  ];

  return (
    <section className="px-6 py-12 bg-gray-900">
      {/* Header */}
      <div className="max-w-4xl mx-auto text-center space-y-4 mb-8">
        <h2 className="text-3xl md:text-4xl text-white font-bold">
          Why Choose Us for Your SSSTS Refresher Training?
        </h2>
        <p className="text-white">
          Our CITB-accredited SSSTS Refresher training provides an outstanding
          learning experience tailored for site supervisors.
        </p>
      </div>

      {/* Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {cards.map((card) => (
          <div
            key={card.id}
            className="bg-white rounded-xl shadow-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-xl font-bold text-gray-900 mb-4">
              {card.title}
            </h3>
            <ul className="space-y-2 text-gray-700">
              {card.points.map((point, idx) => (
                <li key={idx} className="flex items-start">
                  <span className="text-[#FF3131] mr-2">•</span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Footer Text */}
      <p className="mt-8 text-center text-[#FF3131] font-semibold">
        Choose us for a seamless, high-quality SSSTS Refresher training
        experience that sets you apart in the construction industry!
      </p>
    </section>
  );
};

export default WhyChooseCitb;
