import React from "react";
import { FaRegStar } from "react-icons/fa";

const IndustryRecognitionBenefits = () => {
  const benefits = [
    {
      title: "CSCS Card Eligibility",
      description:
        "Apply for the Gold CSCS Advanced Craft Card upon successful completion.",
    },
    {
      title: "Nationally Accredited Certification: ",
      description: "Recognized across the heritage sector.   ",
    },
    {
      title: "Career Growth",
      description:
        "Enhances your credentials for advanced roles in timber conservation and restoration. ",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto py-12 bg-[#0C1524] px-6 md:px-12">
      {/* Heading */}
      <h2 className="text-2xl md:text-3xl text-[#FF3131] font-semibold text-center mb-16">
        Industry Recognition & Benefits
      </h2>

      {/* Timeline */}
      <div className="relative flex flex-col items-center">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className={`relative flex flex-col md:flex-row items-center md:items-start w-full max-w-4xl mb-12 ${
              index % 2 === 0 ? "md:flex-row-reverse" : ""
            }`}>
            {/* Dot and Line */}
            <div className="flex flex-col items-center">
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-white text-[#0C1524] font-bold">
                <FaRegStar />
              </div>
              {index <= benefits.length - 1 && (
                <div className="hidden md:block w-[2px] h-24 bg-gray-300"></div>
              )}
            </div>

            {/* Content */}
            <div
              className={`bg-white p-6 rounded-lg shadow-lg max-w-md md:absolute ${
                index % 2 === 0 ? "right-[60px]" : "left-[60px]"
              } text-center md:text-left`}>
              <h3 className="text-lg md:text-xl font-medium text-[#0C1524] mb-2">
                {benefit.title}
              </h3>
              <p className="text-sm md:text-base text-gray-600">
                {benefit.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndustryRecognitionBenefits;
