import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        This qualification is ideal for roof tilers looking to demonstrate their
        proficiency and expand their expertise in complex roofing tasks. The
        Level 3 NVQ in Roofing Occupations – Roof Tiler is essential for roofers
        aiming for senior roles or seeking to enhance their qualifications with
        a recognized, industry-standard certification.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Confirming Work Activities and Resources for an Occupational
                  Work Area in the Workplace
                </li>
                <li>
                  Developing and Maintaining Good Occupational Working
                  Relationships in the Workplace
                </li>
                <li>
                  Confirming the Occupational Method of Work in the Workplace
                </li>
                <li>
                  Conforming to General Health, Safety, and Welfare in the
                  Workplace
                </li>
                <li>
                  Installing Underlay, Battens, and Roofing Components in the
                  Workplace
                </li>
                <li>
                  Installing Pre-formed Weathering Flashings to Roofs in the
                  Workplace
                </li>
                <li>
                  Stripping and Reclaiming Roof Coverings in the Workplace
                </li>
                <li>
                  Installing Single-lap Roof Tiles to a Fixed Gauge in the
                  Workplace
                </li>
                <li>
                  Installing Double-lap Roof Tiles to Curved and Swept Roof
                  Details in the Workplace
                </li>
                <li>
                  Installing Single and Double-lap Roof Tiles to Mitred and
                  Vertical Roof Details in the Workplace
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units:
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>NA </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
