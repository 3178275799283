import React from "react";
import backgroundImage from "../../../../assets/homePage/m2hse-constructiongirl-backgroundImage.svg";
import icon1 from "../../../../assets/nvq/heritagePainter/m2hse-cap.png";
import icon3 from "../../../../assets/nvq/heritagePainter/m2hse-card-Icon.png";
import icon2 from "../../../../assets/nvq/heritagePainter/m2hse-user-icon.png";
const Hero = () => {
  return (
    <section
      className="hero relative bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-[#0C1524] opacity-90"></div>

      {/* Content */}
      <div className="relative min-h-[500px] flex flex-col justify-center px-4 sm:px-12 lg:px-20">
        <div className="max-w-screen-xl mx-auto text-center">
          {/* Main Heading */}
          <h4 className="text-white text-lg sm:text-xl md:text-2xl mt-36 sm:mt-20 md:mt-24">
            NVQ Level-6 Diploma In
          </h4>
          <h1 className="text-[#FF3131] text-2xl sm:text-3xl md:text-4xl font-bold mt-1">
            Construction Site Management
          </h1>
          <h3 className="text-white text-xl sm:text-xl mt-1">Demolition</h3>

          <div
            class="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="65e09121938229973209b5ac"
            data-style-height="150px"
            data-style-width="100%"
            data-theme="dark"
          >
            <a
              href="https://uk.trustpilot.com/review/m2hse.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>

          {/* Enquire Now */}
          <a href="/contact-us">
            <button className="bg-[#FF3131] text-white py-3 px-8 sm:px-10 md:px-12 mb-4 rounded-2xl sm:text-md md:text-md hover:bg-[#ff4d4d] transition-all">
              Enquire Now
            </button>
          </a>
        </div>
      </div>

      {/* Cards Section */}
      <div className="cards relative sm:px-28 lg:px-28 rounded-t-3xl mx-auto grid sm:grid-cols-3 gap-8">
        {/* Card 1 */}
        <div className="card bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
          <div className="mb-6">
            <img src={icon1} alt="Icon 1" className="h-12 w-12" />
          </div>
          <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
            Finish Your <br /> Qualification in <br />
            <span className="text-[#FF3131] font-semibold">8-12 Weeks</span>
          </p>
        </div>
        {/* Card 2 */}
        <div className="card bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
          <div className="mb-6">
            <img src={icon2} alt="Icon 2" className="h-12 w-12" />
          </div>
          <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
            Apply for a <br />
            <span className="text-[#FF3131] font-semibold">
              Black CSCS Card <br />
            </span>{" "}
            After Completion
          </p>
        </div>
        {/* Card 3 */}
        <div className="card bg-[#101828] text-white shadow-lg py-8 px-6 flex flex-col items-center rounded-t-xl">
          <div className="mb-6">
            <img src={icon3} alt="Icon 3" className="h-12 w-12" />
          </div>
          <p className="text-xs sm:text-sm md:text-base text-center sm:text-left">
            Ofqual & CITB <br />
            <span className="text-[#FF3131] font-semibold">Approved</span>
            <br />
            <span className="text-[#FF3131] font-semibold">Qualification</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
