import React from "react";

const Certification = () => {
  return (
    <section className="p-6  text-gray-800">
      <div className=" mx-auto">
        <h2 className="text-3xl font-bold text-center text-[#FF3131]">
          Certifications{" "}
        </h2>
        <div className="grid gap-6 my-8 lg:grid-cols-3">
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              1
            </div>
            <p className="text-md">
              <b>Industry-Recognized Qualification:</b> Earn the prestigious
              Level 2 NVQ Certificate in Roadbuilding and Maintenance
              (Construction) - Street Ironwork, accredited by renowned bodies
              OfQual and CITB.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              2
            </div>
            <p className="text-md">
              <b>CSCS Card Eligibility:</b> Gain eligibility to apply for the
              highly sought-after Blue CSCS Skilled Worker Card, validating your
              expertise in Roadbuilding and Maintenance (Construction) - Street
              Ironwork.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              3
            </div>
            <p className="text-md">
              <b>Career Advancement:</b> Enhance your professional standing and
              unlock new career opportunities in the dynamic Roadbuilding and
              Maintenance (Construction) - Street Ironwork industry.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              4
            </div>
            <p className="text-md">
              <b>Industry-Endorsed Expertise:</b> This OfQual and
              CITB-accredited qualification demonstrates your mastery of
              Roadbuilding and Maintenance (Construction) - Street Ironwork,
              establishing you as a trusted and respected professional in the
              construction industry.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;
