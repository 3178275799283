import React, { useEffect, useState } from "react";
import axios from "axios";
import citbLogo from "../../assets/CitbPage/citb_logo.png";
import image from "../../assets/CitbPage/imageCitb.png";

const CitbCourses = () => {
  const [coursesData, setCoursesData] = useState([]);

  useEffect(() => {
    axios
      .get("product/filter", {
        params: {
          filter: "productCategory",
          equal: "65e973a001d6a06a746d5d25",
        },
      })
      .then((response) => {
        setCoursesData(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, []);

  return (
    <div className="bg-[#ffffff] mt-32 sm:mt-28 py-8 sm:py-12">
      {/* Logo and Header */}
      <div className="text-center mx-auto px-4 sm:px-6 lg:px-16 mb-8 sm:mb-12">
        <img
          src={citbLogo}
          alt="CITB Logo"
          className="mx-auto mb-4 w-24 sm:w-32 lg:w-40"
        />
        <div class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="65e09121938229973209b5ac" data-style-height="150px" data-style-width="100%">
  <a href="https://uk.trustpilot.com/review/m2hse.co.uk" target="_blank" rel="noopener noreferrer">Trustpilot</a>
</div>
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-[#FF3131] mb-4">
          CITB Courses
        </h1>
        <p className="text-base sm:text-lg lg:text-xl text-gray-600">
          Browse through our CITB-accredited courses to enhance your career in
          site management.
        </p>
      </div>

      {/* Courses Section */}
      <div className="bg-[#0A111D] py-8 sm:py-10 px-4 sm:px-6 lg:px-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
          {coursesData.map((course, index) => (
            <div
              key={course._id}
              className="relative bg-gray-800 text-white rounded-xl shadow-xl overflow-hidden cursor-pointer"
              onClick={() => {
                window.location.href = course.detailsPage;
              }}>
              {/* Refresher Badge */}
              {course.name.includes("Refresher") && (
                <div className="absolute rounded-br-xl top-0 left-0 bg-[#FF3131] text-white text-center text-xs sm:text-sm font-semibold px-3 sm:px-4 py-1 z-10">
                  Refresher
                </div>
              )}

              {/* Image Section */}
              <div className="relative h-46 sm:h-46 lg:h-52">
                <img
                  src={course.image || image}
                  alt={course.name}
                  className="w-full h-full rounded-t-xl object-cover hover:scale-95 transform transition-transform duration-300 ease-in-out"
                />
                <div className="absolute inset-0 bg-[#0A111D] bg-opacity-80"></div>
                <div className="absolute top-10 left-2 right-2 text-center">
                  <h3 className="text-lg sm:text-md font-semibold truncate">
                    {course.name.split("–").join("")}
                  </h3>
                </div>
              </div>

              {/* Card Content */}
              <div className="p-3 sm:p-4 space-y-2">
                <p className="text-xs sm:text-sm text-gray-300 line-clamp-3">
                  {course.description}
                </p>
                <div className="flex justify-between items-center">
                  <p className="text-sm sm:text-base font-semibold text-[#FF3131]">
                    £{course.price} + VAT
                  </p>
                  <button
                    onClick={() => {
                      window.location.href = course.detailsPage;
                    }}
                    className="px-2 sm:px-4 py-1 text-xs sm:text-sm bg-[#FF3131] text-white rounded-lg hover:bg-red-600 transition-colors">
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CitbCourses;
