import React from "react";
import backgroundImage from "../../assets/contactPage/contactUs.png";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineAttachEmail } from "react-icons/md";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import axios from "axios";
import { useMutation } from "react-query";
import { useForm } from "@formspree/react";
import { useToast } from "../../components/Toast/toast";

const ContactPage = () => {
  const useCreatePerson = async ({
    firstname,
    lastname,
    email,
    phone,
    message,
  }) => {
    const response = await axios.post("people/create", {
      firstname,
      lastname,
      email,
      phone,
      message,
    });
    return response.data;
  };

  const [state, handleSubmit] = useForm("xeqypwnz");
  const { isLoading, isError, error, data, mutate } =
    useMutation(useCreatePerson);

  const { addToast } = useToast();
  const handleCombinedSubmit = async (e) => {
    e.preventDefault();

    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;

    try {
      await mutate({ firstname, lastname, email, phone, message });
      addToast(
        "We have received your query, please rest assured our team will get back to you",
        "success"
      );
      e.target.reset();
    } catch (error) {
      console.error(error);
      addToast("An error occurred. Please try again later.", "error");
    }
  };

  return (
    <div
      className="relative bg-cover bg-center min-h-screen flex items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Overlay */}
      <div className="absolute inset-0 bg-[#0C1524] bg-opacity-90"></div>

      {/* Content Container */}
      <div className="relative z-10 mt-40 sm:mt-36 w-full max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Heading Section */}
        <div className="text-center text-white mb-12">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold">
            Contact Us
          </h1>
          <p className="text-lg sm:text-xl mt-2">
            Can't Find What You Are Looking For?
          </p>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Contact Info Section */}
          <div className=" p-6 sm:p-10 space-y-6 ">
            {/* Contact Info Items */}
            <div className="space-y-4">
              {/* Phone */}
              <div className="flex items-center space-x-4 shadow-lg p-2 sm:p-10 space-y-2 bg-gradient-to-r from-black via-gray-900 to-gray-800">
                <LuPhoneCall className="w-8 h-8 text-[#008FFF]" />
                <a
                  href="tel:02080599944"
                  className="hover:underline text-white">
                  02080-599944
                </a>
              </div>
              {/* Email */}
              <div className="flex items-center space-x-4 shadow-lg p-2 sm:p-10 space-y-2 bg-gradient-to-r from-black via-gray-900 to-gray-800">
                <MdOutlineAttachEmail className="w-8 h-8 text-[#008FFF]" />
                <a
                  href="mailto:sales@m2hse.co.uk"
                  className="hover:underline text-white">
                  sales@m2hse.co.uk
                </a>
              </div>
              {/* Address */}
              <div className="flex items-start space-x-4 shadow-lg p-2 sm:p-10 space-y-2 bg-gradient-to-r from-black via-gray-900 to-gray-800">
                <HiOutlineBuildingOffice2 className="w-8 h-8 text-[#008FFF]" />
                <p className="text-white">
                  M2HSE Training Ltd,
                  <br />
                  Ceme Business Campus, Commercial 1,
                  <br />
                  Marsh Way, Rainham, RM13 8EU
                </p>
              </div>
            </div>
          </div>

          {/* Form Section */}
          <div data-clarity-unmask="true" className=" bg-opacity-95 rounded-xl shadow-xl shadow-slate-500 mb-10 p-6 sm:p-10">
            <h2 className="text-xl font-semibold text-[#FF3131] mb-6">
              Enter Details
            </h2>
            <form onSubmit={handleCombinedSubmit} data-clarity-unmask="true" className="space-y-4">
              {/* Name */}
              <div >
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-white">
                  First name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder="first name"
                  required
                  className="mt-1 p-2 block text-white bg-transparent w-full border-gray-300 rounded-xl shadow-lg shadow-slate-500 focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>

               <div >
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-white">
                  Last name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="last name"
                  required
                  className="mt-1 p-2 block text-white bg-transparent w-full border-gray-300 rounded-xl shadow-lg shadow-slate-500 focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>


              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-white">
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="email"
                  required
                  className="mt-1 p-2 block text-white bg-transparent w-full border-gray-300 rounded-xl shadow-lg shadow-slate-500 focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>

              {/* Phone */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-white">
                  Phone<span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="phone no."
                  required
                  className="mt-1 p-2 block text-white bg-transparent w-full border-gray-300 rounded-xl shadow-lg shadow-slate-500 focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>

              {/* Message */}
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-white">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Please drop your query, we would love to help you"
                  rows="4"
                  className="mt-1 p-2 block text-white bg-transparent w-full border-gray-300 rounded-xl shadow-lg shadow-slate-500 focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="w-full px-6 py-2 bg-green-600 text-white font-medium rounded-xl shadow hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
