import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 3 NVQ Diploma in Level 3 NVQ Diploma in Decorative Finishing –
        Painting and Decorating (Construction) is tailored for individuals
        working within Decorative Finishing – Painting and Decorating
        (Construction). This qualification demonstrates your ability to apply
        your skills in Decorative Finishing – Painting and Decorating
        (Construction), ensuring compliance with health and safety standards and
        legal requirements.
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Confirming work activities and resources for an occupational
                  work area in the workplace
                </li>
                <li>
                  Developing and maintaining good occupational working
                  relationships in the workplace
                </li>
                <li>
                  Confirming the occupational method of work in the workplace
                </li>
                <li>
                  Erecting and dismantling access/working platforms in the
                  workplace
                </li>
                <li>
                  Preparing surfaces for painting and/or decorating in the
                  workplace
                </li>
                <li>
                  Applying surface coatings by brush and roller in the workplace
                </li>
                <li>
                  Conforming to general health, safety and welfare in the
                  workplace
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Unit from Group A plus ONE Optional Unit from Group B
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <ul>
                  <li>
                    Hang wallcoverings (standard and foundation papers) in the
                    workplace
                  </li>
                  <li>
                    Hang non-standard width wallcoverings in the workplace
                  </li>
                </ul>

                <ul>
                  <li>
                    Applying coatings by the airless spray method in the
                    workplace
                  </li>
                  <li>
                    Applying coatings by the air spray method in the workplace
                  </li>
                  <li>
                    Producing and applying complex stencils in the workplace
                  </li>
                  <li>
                    Hanging wallcoverings to complex surfaces in the workplace
                  </li>
                  <li>
                    Hanging wallcoverings (specialised paper) in the workplace
                  </li>
                  <li>Producing comb textured finishes in the workplace</li>
                  <li>Applying metal leaf to surfaces in the workplace</li>
                  <li>
                    Installing coving and decorative mouldings in the workplace
                  </li>
                  <li>
                    Applying coatings to produce textured finishes in the
                    workplace
                  </li>
                  <li>Producing broken colour effects in the workplace</li>
                  <li>
                    Producing stencil designs using pre-cut stencil plates in
                    the workplace
                  </li>
                  <li>
                    Producing straight wood grained effects in the workplace
                  </li>
                  <li>
                    Producing quality replica wood grained finishes in the
                    workplace
                  </li>
                  <li>Producing marbling effects in the workplace</li>
                  <li>
                    Producing quality replica marble finishes in the workplace
                  </li>
                </ul>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
