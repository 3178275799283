import React, { useState } from "react";

const AssessmentBox = () => {
  const [isMandatoryOpen, setIsMandatoryOpen] = useState(false);
  const [isSpecializedOpen, setIsSpecializedOpen] = useState(false);

  const toggleMandatory = () => setIsMandatoryOpen(!isMandatoryOpen);
  const toggleSpecialized = () => setIsSpecializedOpen(!isSpecializedOpen);

  return (
    <div className=" max-w-7xl mx-auto py-8 px-4 md:py-12 md:px-16">
      {/* Main Heading */}
      <h2 className="text-2xl md:text-3xl font-semibold text-center mb-4">
        About This <span className="text-red-500">NVQ Assessment</span>
      </h2>
      {/* Description */}
      <p className="text-center text-gray-600 text-sm md:text-base mb-8">
        The Level 3 NVQ Diploma in Demolition (Construction) - Demolition (Top
        Man) Operative is tailored for individuals working within Demolition
        (Construction) - Demolition (Top Man) Operative. This qualification
        demonstrates your ability to apply your skills in Demolition
        (Construction) - Demolition (Top Man) Operative, ensuring compliance
        with health and safety standards and legal requirements
      </p>
      {/* Subheading */}
      <h3 className="text-xl md:text-2xl font-medium text-center mb-6">
        About This NVQ Diploma Assessment
      </h3>
      {/* Dropdown Sections */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Mandatory Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524] text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleMandatory}>
            Mandatory Unit
            <span>{isMandatoryOpen ? "˄" : "˅"}</span>
          </button>
          {isMandatoryOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Removing and segregating components and materials in
                  demolition and/or refurbishment in the workplace
                </li>
                <li>
                  Confirming work activities and resources for an occupational
                  work area in the workplace
                </li>
                <li>
                  Developing and maintaining good occupational working
                  relationships in the workplace
                </li>
                <li>
                  Confirming the occupational method of work in the workplace
                </li>
                <li>
                  Implementing and maintaining health, safety, environmental and
                  welfare practices in the workplace
                </li>
                <li>
                  Co-ordinating and confirming the dimensional control
                  requirements of the work in the workplace
                </li>
                <li>
                  Installing, constructing, maintaining, dismantling and
                  removing temporary works in the workplace
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* Specialized Unit */}
        <div className=" rounded-xl ">
          <button
            className="w-full text-left p-4 bg-[#0C1524]  text-white font-medium flex justify-between rounded-t-xl items-center"
            onClick={toggleSpecialized}>
            Optional Units: three optional units are required
            <span>{isSpecializedOpen ? "˄" : "˅"}</span>
          </button>
          {isSpecializedOpen && (
            <div className="p-4 bg-[#2E3847] rounded-b-xl text-white">
              <ul className="list-disc list-inside">
                <li>
                  Dismantling and/or demolishing masonry and/or concrete
                  structures in the workplace
                </li>
                <li>
                  Dismantling and/or demolishing roofs and/or cladding in the
                  workplace
                </li>
                <li>
                  Dismantling and/or demolishing timber structures in the
                  workplace
                </li>
                <li>
                  Dismantling and/or demolishing metal structures in the
                  workplace
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentBox;
