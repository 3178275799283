import React from "react";

const Certification = () => {
  return (
    <section className="p-6  text-gray-800">
      <div className=" mx-auto">
        <h2 className="text-3xl font-bold text-center text-[#FF3131]">
          Certifications{" "}
        </h2>
        <div className="grid gap-6 my-8 lg:grid-cols-3">
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              1
            </div>
            <p className="text-md">
              <b>Recognized Qualification:</b> Earn the Level 3 NVQ Diploma in
              Interior Systems - Complex Suspended Ceiling Systems, accredited
              by OfQual and CITB.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              2
            </div>
            <p className="text-md">
              <b> CSCS Card Eligibility:</b>Qualify to apply for the Gold CSCS
              Advanced Craft Card, validating your advanced expertise.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              3
            </div>
            <p className="text-md">
              Strengthen your credentials and open doors to leadership roles in
              the interior systems and construction sectors.
            </p>
          </div>
          <div className="flex flex-col p-8 space-y-4 rounded-xl shadow-lg shadow-[#FF3131] bg-white border border-[#101C34]">
            <div className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-sm rounded-full bg-[#FF3131] text-gray-50">
              4
            </div>
            <p className="text-md">
              This OfQual and CITB-accredited qualification certifies your
              expertise in complex suspended ceiling systems, positioning you as
              a trusted professional in advanced interior construction projects.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;
